import { AlertFilters, SubscriptionUpdatableProps } from '@eagle/core-data-types';
import { Autocomplete, Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MiddleSpinner } from '../../components';
import { T_ONE } from '../../constants';
import { Nullable } from '../../types';
import { FeatureWithInstancesAndEvents, getEventFeature, getEventLabel, getFeatureInstance, useMyAlertableFeaturesQuery } from '../../util';
import { EntityState, Settings, SettingsWithFeatureInstance } from './subscription-detail.types';

interface Props {
  alertFilters: AlertFilters;
  defaultSubscription: SubscriptionUpdatableProps;
  fieldDisabled: boolean;
  setFeatureAlertState: (state: Settings) => void;
  thingPeopleState: EntityState;
}

export const FeatureAlertSettingsCardV2: FC<Props> = ({
  alertFilters,
  defaultSubscription,
  fieldDisabled,
  thingPeopleState,
  setFeatureAlertState,
}) => {
  const { t } = useTranslation(['admin']);
  const initialValues = useRef(getInitialValues(alertFilters));
  const [values, setValues] = useState<SettingsWithFeatureInstance>(initialValues.current);

  useEffect(() => {
    const feature = values.feature ?
      `${values.feature}${values.featureInstance ? `/${values.featureInstance}` : ''}`
      : null;

    setFeatureAlertState({
      alertTypeId: values.alertTypeId,
      feature,
      featureTypeIdPrefix: values.featureTypeIdPrefix,
    });
  }, [values, setFeatureAlertState]);

  const { data: features, status } = useMyAlertableFeaturesQuery(thingPeopleState?.thing._id ?? null);

  const isInitialValues = isEqual(initialValues.current, values) && thingPeopleState.thing._id === defaultSubscription.alertFilters.thingId;

  const renderBody = (): JSX.Element => {
    if (status === 'pending') {
      return <MiddleSpinner />;
    }

    if (status === 'error') {
      return <Typography>{t('admin:common.hint.unable-to-load-entity', { entity: t('common:common.labels.features') })}</Typography>;
    }

    return (
      <FeatureAlertSettings
        features={features}
        fieldDisabled={fieldDisabled}
        isInitialValues={isInitialValues}
        setValues={setValues}
        values={values}
      />
    );
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h5">{t('admin:page.create-subscription.feature-alert-setting.labels')}</Typography>
          {renderBody()}
        </Stack>
      </CardContent>
    </Card>
  );
};

interface FeatureAlertSettingsProps {
  features: FeatureWithInstancesAndEvents[];
  fieldDisabled: boolean;
  isInitialValues: boolean;
  setValues: Dispatch<SetStateAction<SettingsWithFeatureInstance>>;
  values: SettingsWithFeatureInstance;
}

const FeatureAlertSettings: FC<FeatureAlertSettingsProps> = ({
  features,
  fieldDisabled,
  isInitialValues,
  setValues,
  values,
}) => {
  const { t } = useTranslation(['common']);

  const selectedFeature = features.find(({ feature }) => values.feature === feature);
  const hasInstances = selectedFeature && selectedFeature.instances.length > 0;
  const isAlertTypeDisabled = fieldDisabled || !values.feature || (hasInstances && !values.featureInstance);

  useEffect(() => {
    if (values.feature && !isInitialValues) {
      if (!features.find(({ feature }) => feature === values.feature)) {
        setValues((values) => ({
          ...values,
          feature: null,
          featureInstance: null,
          alertTypeId: null,
        }));
      }
    }
  }, [features, values, setValues, isInitialValues]);

  return (
    <Stack spacing={2}>
      <Autocomplete
        data-testid="feature-select"
        disabled={fieldDisabled}
        disablePortal
        getOptionLabel={(option) => getEventFeature(option)}
        noOptionsText={t('common:common.hint.list.no-results')}
        onChange={(_, value) => {
          setValues({ feature: value, alertTypeId: null, featureInstance: null, featureTypeIdPrefix: null });
        }}
        options={features.map(({ feature }) => feature)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('common:terms.feature')}
            variant="outlined"
          />
        )}
        size="small"
        value={values.feature}
      />
      {hasInstances && (
        <Autocomplete
          data-testid="feature-instance-select"
          disabled={fieldDisabled}
          disablePortal
          getOptionLabel={(option) => getFeatureInstance(`${selectedFeature.feature}/${option}`) ?? option}
          noOptionsText={t('common:common.hint.list.no-results')}
          onChange={(_, value) => {
            setValues((values) => ({
              ...values,
              featureInstance: value,
              ...(!value && { alertTypeId: null }),
            }));
          }}
          options={selectedFeature.instances}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('common:terms.instance', { count: T_ONE })}
              variant="outlined"
            />
          )}
          size="small"
          value={values.featureInstance}
        />
      )}
      <Autocomplete
        data-testid="alert-type-select"
        disablePortal
        getOptionLabel={(option) => values.feature ? getEventLabel(values.feature, option) : option}
        noOptionsText={t('common:common.hint.list.no-results')}
        disabled={isAlertTypeDisabled}
        onChange={(_, value) => {
          setValues((values) => ({ ...values, alertTypeId: value }));
        }}
        options={selectedFeature?.alertableEvents ?? []}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('common:terms.alert-type')}
            variant="outlined"
          />
        )}
        size="small"
        value={values.alertTypeId}
      />
    </Stack>
  );
};

const parseFeature = (feature: Nullable<string>): Nullable<{ feature: string; featureInstance: Nullable<string> }> => {
  if (!feature) return null;

  const [featurePart, ...instanceParts] = feature.split('/');
  return {
    feature: featurePart,
    featureInstance: instanceParts.join('/') || null,
  };
};

const getInitialValues = (alertFilters: AlertFilters): SettingsWithFeatureInstance => {
  const parsedFeature = parseFeature(alertFilters.feature);

  return {
    alertTypeId: alertFilters.alertTypeId,
    feature: parsedFeature?.feature ?? null,
    featureInstance: parsedFeature?.featureInstance ?? null,
    featureTypeIdPrefix: alertFilters.featureTypeIdPrefix,
  };
};
