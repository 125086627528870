import { Json } from '@eagle/common';
import { Dashboard, DashboardType, DashboardUpdatableProps } from '@eagle/core-data-types';
import { formatDateToDateTime, LoadingButton, SetState, TextEditor, useApiErrorHandler, useAuthenticated } from '@eagle/react-common';
import { Card, CardContent, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  data: Dashboard;
  setPageType: SetState<DashboardType>;
}

export const DashboardDetailCard: FC<Props> = ({ data, setPageType }) => {
  const { restClient } = useAuthenticated();
  const { handleUpdateError } = useApiErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['track']);
  const [dataState, setDataState] = useState(data);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const handlePropertyChange = (property: string) => (value: Json): Promise<void> => {
    setDataState((prev) => ({ ...prev, [property]: value }));
    return Promise.resolve();
  };

  const getUpdatableProps = (dashboard: Dashboard): Partial<DashboardUpdatableProps> => ({
    display: dashboard.display,
    type: dashboard.type,
  });

  const updateEntity = async (): Promise<void> => {
    try {
      setSaveInProgress(true);
      const data = await restClient.dashboard.partialUpdate(dataState._id, getUpdatableProps(dataState));
      setDataState(data);
      enqueueSnackbar(t('common:common.hint.update-success', { entity: data.display }), { variant: 'success' });
      setPageType(data.type);
    } catch (err) {
      handleUpdateError(err, data.type, dataState.display);
    } finally {
      setSaveInProgress(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <TextField
            disabled
            label={t('common:common.labels.id')}
            size="small"
            value={dataState._id}
          />
          <TextEditor
            data-testid="dashboard-display-input"
            label={t('common:common.labels.display')}
            onChange={handlePropertyChange('display')}
            required
            size="small"
            value={dataState.display}
          />
          <TextField
            disabled
            label={t('track:page.dashboard-detail.last-published.labels')}
            size="small"
            value={formatDateToDateTime(dataState.lastPublish)}
          />
          <TextField
            disabled
            label={t('track:page.dashboard-detail.last-updated.labels')}
            size="small"
            value={formatDateToDateTime(dataState.lastUpdated)}
          />
          <TextEditor
            data-testid="dashboard-type-input"
            label={t('track:page.dashboard-detail.type.labels')}
            onChange={handlePropertyChange('type')}
            required
            size="small"
            value={dataState.type}
          />
          <LoadingButton
            data-testid="save-button"
            disabled={saveInProgress}
            loadingCaption={t('common:common.hint.saving')}
            sx={{ alignSelf: 'end', minWidth: 120 }}
            task={updateEntity}
          >
            {t('common:common.action.save')}
          </LoadingButton>
        </Stack>
      </CardContent>
    </Card>
  );
};
