import CancelIcon from '@mui/icons-material/Cancel';
import {
  Autocomplete,
  Checkbox,
  Chip,
  createFilterOptions,
  ListItem,
  ListItemText,
  Stack,
  TextField
} from '@mui/material';
import { isArray } from 'lodash';
import { FC, FocusEvent, KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MiddleSpinner, Spinner } from '../middle-spinner';

const SELECT_ALL = 'SELECT_ALL';

export interface TagsEditorProps {
  'data-testid'?: string;
  disabled?: boolean;
  error?: boolean;
  handleInputChange?: (value: string) => void;
  label?: string;
  noOptionsText?: string;
  onChange: (tags: string[]) => Promise<void>;
  onSelectAll?: (tags: string[]) => Promise<void>;
  optionOnClick?: (tag: string, updatedTags?: string[]) => Promise<void>;
  optionOnDelete?: (tag: string, updatedTags?: string[]) => Promise<void>;
  options?: string[];
  placeholder?: string;
  size?: 'small' | 'medium';
  tags: string[];
  variant?: 'filled' | 'outlined';
}

enum AutoCompleteChangedReason {
  'CREATE' = 'createOption',
  'SELECT' = 'selectOption',
  'REMOVE' = 'removeOption',
  'CLEAR' = 'clear',
  'BLUR' = 'blur'
}

const filter = createFilterOptions<string>();

export const TagsEditor: FC<TagsEditorProps> = ({ disabled = false, error = false, handleInputChange, label, noOptionsText, onChange, onSelectAll, optionOnClick, optionOnDelete, options, placeholder, size, tags: inputTags, variant, ...props }) => {
  const { t } = useTranslation(['common']);
  const [tags, setTags] = useState<string[]>(inputTags);
  const [optionLoading, setOptionLoading] = useState<string>();
  const [dynamicLabel, setDynamicLabel] = useState(label);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(error);
  const [deleting, setDeleting] = useState<string>();
  const [open, setOpen] = useState(false);
  const isSearchable = isArray(options);
  const tagsAndOptions = isSearchable && !options[0] ? inputTags : options;
  const disableInput = disabled || Boolean(deleting) || Boolean(optionLoading) || isLoading;
  const textInputDisabled = disabled ? disabled : !isSearchable ? disableInput : false;
  const inputRef = useRef<HTMLInputElement>(null);

  const updateTags = async (updatedTags: string[]): Promise<void> => {
    setIsLoading(true);
    await onChange(updatedTags)
      .then(() => {
        setIsLoading(false);
        setTags(updatedTags);
      })
      .catch(() => setIsLoading(false));
  };

  const onInputKeyDown = async (e: KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (disableInput) return e.preventDefault();
    if (isSearchable) return;
    const target = e.target as HTMLInputElement;
    const tagToSave = target.value?.trim();
    if (tagToSave && e.key === 'Enter' && !tags.includes(tagToSave)) {
      await updateTags([...tags, tagToSave]);
      if (inputRef && inputRef.current) inputRef.current.focus();
    }
  };

  const onInputChange = (e: SyntheticEvent, value: string): void => {
    handleInputChange?.(value);
    const tagExists = tags.includes(value);
    if (e && tagExists) {
      setIsError(true);
      setDynamicLabel(t('common:component.tags-editor.hint.duplicate'));
    } else {
      setIsError(false);
      setDynamicLabel(label);
    }
  };

  const onBlur = async (e: FocusEvent<HTMLInputElement>): Promise<void> => {
    if (isSearchable) return;
    const tagToSave = e.target.value?.trim();
    if (tagToSave && !tags.includes(tagToSave)) {
      await updateTags([...tags, tagToSave]);
    }
  };

  const removeTag = async (tagToRemove: string): Promise<void> => {
    const updatedTags = tags.reduce((acc, tag) => {
      if (tag !== tagToRemove) acc.push(tag);
      return acc;
    }, [] as string[]);
    if (optionOnDelete) {
      const request = await optionOnDelete(tagToRemove, updatedTags).catch((requestError: Error) => requestError);
      if (request?.message) return Promise.resolve();
    }
    await updateTags(updatedTags);
  };

  const onOpen = (): void => !disableInput ? setOpen(true) : undefined;

  const onClose = (): void => !optionLoading ? setOpen(false) : undefined;

  const autoCompleteChanged = async (option: string, reason?: string): Promise<void> => {
    if (!isSearchable || optionLoading || disableInput) return;
    const updatedTags = tags.includes(option) ? tags.filter((tag) => tag !== option) : [...tags, option];
    if (reason === AutoCompleteChangedReason.REMOVE) {
      onClose();
      return setTags(tags);
    }
    setOptionLoading(option);
    if (optionOnClick) {
      const request = await optionOnClick(option, updatedTags).catch((requestError: Error) => requestError);
      if (request?.message) {
        setOptionLoading(undefined);
        onClose();
        return Promise.resolve();
      }
    }
    await updateTags(updatedTags);
    setOptionLoading(undefined);
    if (!onSelectAll) {
      onClose();
    }
  };

  const toggleSelectAll = async (): Promise<void> => {
    if (!onSelectAll) {
      return;
    }

    if (tags.length === (tagsAndOptions ?? []).length) {
      await onSelectAll([]);
      await updateTags([]);
    }
    else {
      await onSelectAll(tagsAndOptions ?? []);
      await updateTags(tagsAndOptions ?? []);
    }
    onClose();
  };

  useEffect(() => setTags(inputTags), [inputTags]);

  return (
    <Stack spacing={3} sx={{ width: 'inherit' }}>
      <Autocomplete
        data-testid={props['data-testid']}
        disableClearable
        disabled={textInputDisabled}
        filterSelectedOptions={!onSelectAll}
        filterOptions={onSelectAll ? (o, state) => {
          const filtered = filter(o, state);
          return [SELECT_ALL, ...filtered];
        } : undefined}
        freeSolo={!isSearchable}
        multiple
        onBlur={onBlur}
        clearOnBlur={true}
        onChange={async (_, updatedTags, reason) => {
          const tagToAdd = updatedTags.filter((tag) => !tags.includes(tag))[0];
          if (tagToAdd === SELECT_ALL) {
            await toggleSelectAll();
            return;
          }
          await autoCompleteChanged(tagToAdd, reason);
        }}
        noOptionsText={noOptionsText}
        onClose={onClose}
        onInputChange={(e, value) => onInputChange(e, value)}
        onKeyDown={onInputKeyDown}
        onOpen={onOpen}
        open={open || Boolean(optionLoading)}
        options={tagsAndOptions ?? []}
        popupIcon={<></>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={dynamicLabel}
            variant="outlined"
            placeholder={options?.length === tags?.length || textInputDisabled ? noOptionsText : placeholder}
            data-testid="tags-editor"
            disabled={textInputDisabled}
            error={error || isError ? true : undefined}
            sx={{ 'input': { minWidth: '3em !important' } }}
            inputRef={inputRef}
          />
        )}
        renderTags={(value: readonly string[]) =>
          value.map((tag: string, index: number) => (
            <Chip
              data-testid={`${index}-chip`}
              disabled={disableInput}
              deleteIcon={deleting === tag ? <Spinner size="1rem" sx={{ m: '0 6.5px 0 -6px' }} /> : <CancelIcon />}
              key={index}
              label={tag}
              onDelete={async () => {
                if (isSearchable) setDeleting(tag);
                onClose();
                await removeTag(tag);
                setDeleting(undefined);
                setIsLoading(false);
              }}
              size={size}
              sx={{ margin: '0.2rem' }}
              variant={variant}
            />
          ))
        }
        renderOption={(defaultProps, option, state) => {
          return (
            <ListItem
              divider={option === SELECT_ALL}
              secondaryAction={<span style={{ display: optionLoading === option ? 'block' : 'none' }}><MiddleSpinner size="1rem" /></span>}
              {...defaultProps}
              key={state.index}
              onClick={async (): Promise<void> => {
                if (option === SELECT_ALL && onSelectAll) {
                  await toggleSelectAll();
                  return;
                }
                if (optionLoading) return;
                setOptionLoading(option);
                await autoCompleteChanged(option);
              }}
            >
              {Boolean(onSelectAll) && (
                <Checkbox
                  checked={option === SELECT_ALL ? tags.length === (tagsAndOptions ?? []).length : tags.includes(option)}
                  size="small"
                  sx={{ mr: 1, p: 0 }} />
              )}
              <ListItemText primary={option === SELECT_ALL ? <strong>{t('common:component.filter-dropdown.labels.select-all')}</strong> : option} />
            </ListItem>
          );
        }}
        size={size}
        value={tags}
      />
    </Stack>
  );
};
