import { ThingEvent } from '@eagle/data-function-types';
import { ifTranslationExists } from '@eagle/react-common';

export type ThingEventFilter = Pick<ThingEvent, 'feature' | 'eventTypeId' | 'featureTypeId'>

export const getFeatureTranslation = (feature: string): string => {
  const [featureId, ...instanceParts] = feature.split('/');
  if (instanceParts.length > 0) {
    const instance = instanceParts.join('.');
    return ifTranslationExists(`common:feature-instances.${featureId}.${instance}`, `${featureId}.${instance}`);
  }
  return ifTranslationExists(`common:features.${featureId}`, featureId);
};

type SimplifiedThingEventFilter = Omit<ThingEventFilter, 'eventTypeId'> & {
  eventTypeId: string | { '$in': string[] };
}

export const simplifyThingEventFilter = (data: ThingEventFilter[]): SimplifiedThingEventFilter[] => {
  const output: SimplifiedThingEventFilter[] = [];
  for (const item of data) {
    const { feature, eventTypeId, featureTypeId } = item;
    const existing = output.find((data) => data.feature === feature && data.featureTypeId === featureTypeId);
    if (existing) {
      if (typeof existing.eventTypeId === 'string') {
        existing.eventTypeId = { $in: [existing.eventTypeId, eventTypeId] };
      }
      else {
        existing.eventTypeId.$in.push(eventTypeId);
      }
    }
    else {
      output.push({ feature, featureTypeId, eventTypeId });
    }
  }
  return output;
};
