import { Button, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../loading-button/loading-button';

interface Props {
  'data-testid'?: string;
  buttonSize?: 'small' | 'medium' | 'large';
  confirmPrompt: JSX.Element;
  hasCancel?: boolean;
  okText?: string;
  onCancel?: () => unknown;
  onConfirm: () => Promise<unknown>;
  onFailure?: (err: unknown) => void;
  onSuccess?: () => void;
  setOpen: (open: boolean) => unknown;
  stackDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}

export const ConfirmDialogConfirmation: FC<Props> = ({
  buttonSize = 'small',
  confirmPrompt,
  hasCancel = true,
  okText,
  onCancel,
  onConfirm,
  onFailure,
  onSuccess,
  setOpen,
  stackDirection,
  ...props
}): JSX.Element => {
  const { t } = useTranslation(['common']);
  const [isLoading, setIsLoading] = useState(false);

  const resetState = (): void => {
    setIsLoading(false);
    setOpen(false);
  };

  return <Stack data-testid={props['data-testid']} alignItems="center" spacing={1} direction={stackDirection}>
    {confirmPrompt}
    <Stack spacing={0.5} direction="row" justifyContent="space-between">
      <LoadingButton
        loadingCaption={t('common:common.labels.loading')}
        size={buttonSize}
        task={() => {
          setIsLoading(true);
          return onConfirm()
            .then(() => { setIsLoading(false); onSuccess?.(); })
            .catch(onFailure)
            .finally(resetState);
        }}
        variant="contained"
        data-testid="button-yes"
        onMouseDown={(e) => e.stopPropagation()}
      >
        {okText ?? t('common:common.action.yes')}
      </LoadingButton>
      {hasCancel
        && <Button
          disabled={isLoading}
          onClick={(e) => { e.preventDefault(); setOpen(false); onCancel && onCancel(); }}
          onMouseDown={(e) => e.stopPropagation()}
          size={buttonSize}
          variant="outlined"
          data-testid="button-no"
        >
          {t('common:common.action.no')}
        </Button>
      }
    </Stack>
  </Stack>;
};

export default ConfirmDialogConfirmation;
