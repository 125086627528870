import { ServiceType } from '@eagle/core-data-types';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolFlag, useNumberFlag } from '../flags';

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  serviceTypes: ServiceType[];
}

export const ServiceTypeSelect: FC<Props> = ({ onChange, value, serviceTypes }) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<string>('');
  const displayServiceTypeFlag = useBoolFlag('portals-servicing-module-display-service-type-details-feature-temporary');
  const numberOfLinesServiceType = useNumberFlag('portals-servicing-module-display-service-type-details-number-of-lines-feature-temporary');
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    if (serviceTypes && serviceTypes.length > 0) {
      if (value) {
        setSelectedValue(value);
      } else {
        setSelectedValue(serviceTypes[0].serviceTypeId);
        onChange({ target: { value: serviceTypes[0].serviceTypeId } });
      }
    }
  }, [serviceTypes, value, onChange]);

  return (
    <FormControl size='small'>
      <InputLabel id="select-service-type-label">
        {t('common:page.thing-detail.service-history-dialog.select-service-type.labels')}
      </InputLabel>
      <Select
        data-testid="service-type-input"
        labelId="select-service-type-label"
        label={t('common:page.thing-detail.service-history-dialog.select-service-type.labels')}
        MenuProps={{
          style: { zIndex: 1303 },
          sx: {
            '& > .MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
              maxWidth: ref.current?.clientWidth,
            },
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSelectedValue(e.target.value);
          onChange(e);
        }}
        value={selectedValue}
        ref={ref}
        renderValue={(value) => {
          const selectedType = serviceTypes.find((serviceType) => serviceType.serviceTypeId === value);
          return <>{selectedType?.display}</>;
        }}
      >
        {serviceTypes.map((serviceType, i) => (
          <MenuItem key={i} value={serviceType.serviceTypeId} data-testid={`select-menu-item-${i}`} divider={displayServiceTypeFlag}>
            <Box>
              <Typography sx={{ whiteSpace: 'wrap' }}>{serviceType.display}</Typography>
              {displayServiceTypeFlag && serviceType.details
                && <Typography
                  color="text.secondary" variant="body2" mt={1}
                  maxWidth="100%"
                  sx={{
                    overflowWrap: 'anywhere',
                    whiteSpace: 'pre-line',
                    ...(numberOfLinesServiceType ? {
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: numberOfLinesServiceType,
                    } : {}),
                  }}
                >
                  {serviceType.details}
                </Typography>
              }
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
