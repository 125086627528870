import { PersonType, ThingType } from '@eagle/core-data-types';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Card, CardContent, CardHeader, Collapse, Divider, IconButton, Link, Stack, Typography, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, FC, SetStateAction } from 'react';
import { useHref } from 'react-router';
import { useCustomRoutes, useDynamicModule } from '../../hooks';
import { CacheDataTypes, FeatureIcons, Maybe } from '../../types';
import { DateTimeRangePickerProvider } from '../../util';
import { DynamicIcon, PortalFeatureIcons } from '../icons';
import { InlineDateTimeRangePicker } from '../inline-date-time-range-picker/inline-date-time-range-picker';
import { EventLocationData } from '../map';
import { SimpleElement } from '../simple-element';
import { OverflowTooltip } from '../tooltip';
import { EntityItems } from './entity-journey-types';
import { HISTORY_DATE_RANGE_FLAG, HISTORY_DEFAULT_DATE_RANGE_UNIT, HISTORY_DEFAULT_MAX_DATE_RANGE, JourneyInstance } from './journey-instance';
import { JourneyInstanceNoDate } from './journey-instance-no-date';
import { useHistorySearch } from './use-history-search';

interface Props {
  'data-testid'?: string;
  entityDataType: ThingType | PersonType;
  entityDisplay: string;
  entityItem: EntityItems;
  handleMoreButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleVisibilityButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  hoveredEventId: Maybe<string>;
  isEntityVisible: boolean;
  selectedEvent: Maybe<EventLocationData>;
  setHoveredEventId: Dispatch<SetStateAction<Maybe<string>>>;
  setSelectedEvent: Dispatch<SetStateAction<Maybe<EventLocationData>>>;
}

export const EntityCard: FC<Props> = ({
  entityDataType,
  entityDisplay,
  entityItem,
  handleMoreButtonClick,
  handleVisibilityButtonClick,
  hoveredEventId,
  isEntityVisible,
  selectedEvent,
  setHoveredEventId,
  setSelectedEvent,
  ...props
}) => {
  const theme = useTheme();
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);
  const { addJourneyToEntity, closeAddDateTimePicker, dateAddingJourney } = useHistorySearch();
  const flags = useFlags();
  const maxDateRange = flags[HISTORY_DATE_RANGE_FLAG] as number || HISTORY_DEFAULT_MAX_DATE_RANGE;
  const { thing: customRoutesThing, person: customRoutesPerson } = useCustomRoutes();
  const href = useHref(`/${entityItem.entity === CacheDataTypes.THING ? customRoutesThing : customRoutesPerson}/${entityItem.id}`);

  const handleAddDateTime = (startDate: Date, endDate: Date): void => {
    addJourneyToEntity(startDate, endDate);
    closeAddDateTimePicker();
  };

  const headerActions = [
    {
      icon: isEntityVisible ? <Visibility /> : <VisibilityOff />,
      onClick: handleVisibilityButtonClick,
    },
    {
      icon: <MoreVertIcon />,
      onClick: handleMoreButtonClick,
    },
  ];

  if (!moduleLoaded) return <></>;

  return (
    <Card data-testid={props['data-testid']}>
      <CardHeader
        action={
          <Stack direction="row" spacing={1}>
            {headerActions.map(({ icon, onClick }, i) =>
              <IconButton key={i} onClick={onClick} sx={{ p: 0 }}>
                {icon}
              </IconButton>,
            )}
          </Stack>
        }
        avatar={
          entityItem.entity === CacheDataTypes.THING
            ? <DynamicIcon icon={module?.ThingIcon ? <module.ThingIcon /> : null} color={theme.palette.text.secondary} />
            : <DynamicIcon icon={module?.PersonIcon ? <module.PersonIcon /> : null} color={theme.palette.text.secondary} />
        }
        sx={{
          p: 2,
          '& .MuiCardHeader-action': { alignSelf: 'center', m: 0 },
          '& .MuiCardHeader-content': { minWidth: 0 },
        }}
        title={
          <Stack>
            <Typography
              color="text.secondary"
              component="label"
              sx={{
                cursor: 'pointer',
                fontSize: '0.8rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <SimpleElement data={entityDataType} />
            </Typography>
            <Link
              component="a"
              href={href}
              underline="none"
            >
              <OverflowTooltip
                sx={{
                  fontSize: '1rem',
                  lineHeight: '1.5',
                  whiteSpace: 'nowrap',
                  width: '100%',
                }}
              >
                {entityDisplay}
              </OverflowTooltip>
            </Link>
          </Stack>
        }
      />
      <Divider />
      <CardContent sx={{ p: '16px !important' }}>
        <Stack spacing={1}>
          {entityItem.journeyList.length
            ? entityItem.journeyList.map((journey, index) => (
              <JourneyInstance
                key={journey.id}
                data-testid={`journey-instance-${index}`}
                data={journey}
                entityDisplay={entityDisplay}
                hoveredEventId={hoveredEventId}
                selectedEvent={selectedEvent}
                setHoveredEventId={setHoveredEventId}
                setSelectedEvent={setSelectedEvent}
              />
            ))
            : <JourneyInstanceNoDate entityId={entityItem.id} />
          }
          <Collapse in={dateAddingJourney?.entityId === entityItem.id} unmountOnExit>
            <DateTimeRangePickerProvider>
              <InlineDateTimeRangePicker
                data-testid="date-time-picker"
                closeDateTimePicker={closeAddDateTimePicker}
                hideSeconds
                maxDateRange={maxDateRange}
                maxRangeUnit={HISTORY_DEFAULT_DATE_RANGE_UNIT}
                onDateRangeChanged={handleAddDateTime}
              />
            </DateTimeRangePickerProvider>
          </Collapse>
        </Stack>
      </CardContent>
    </Card>
  );
};
