import { Operator } from '@jexop/core';
import { isNumber } from 'lodash';
import { Nullable } from '../types';

export const formatNumber: Operator<string> = ({ value, format, type }): Nullable<string> => {
  if (!isNumber(value)) {
    return null;
  }

  if (type === 'integer') {
    return Math.round(value).toString();
  }

  let val;
  switch (format) {
    case 'percentage':
      val = (value * 100).toFixed(2) + '%';
      break;
    case 'percentage-100':
      val = value.toString() + '%';
      break;
    case 'currency':
      val = '$' + value.toString();
      break;
    case 'voltage':
      val = (value / 1000).toFixed(3).replace(/\.?0+$/, '') + ' V';
      break;
    default:
      val = value.toString();
  }
  return val;
};
