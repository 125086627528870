import { AxiosError } from 'axios';
import { Issue, Path } from 'validata';

export class CodedError extends Error {
  constructor(message: string, public readonly code: string) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, CodedError.prototype);
  }
}

export const isAxiosError = (error: Error): error is AxiosError => {
  return !!(error as AxiosError).isAxiosError;
};

export const getErrorReason = (error: unknown): { path?: Path; reason?: string } => {
  const issues = (error as AxiosError<{ issues?: Issue[] }>).response?.data.issues;
  const path = issues?.[0]?.path?.[0];
  const reason = issues?.[0]?.reason;
  return { path, reason };
};
