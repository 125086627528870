import { CreateServiceHistoryRequest, ServiceHistory, ServiceHistoryUpdatableProps } from '@eagle/core-data-types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { Undefinable } from '../../types';

interface UpdateServiceRequest {
  isNextServiceExist: boolean;
  currentService: ServiceHistoryUpdatableProps;
  nextService?: ServiceHistoryUpdatableProps;
}

export interface CreateServiceRequest {
  currentService: CreateServiceHistoryRequest;
  nextService?: ServiceHistoryUpdatableProps;
}

interface UpdateServiceOptions {
  errorMessage: string;
  onFailure: () => void;
  onSuccess: () => void;
  successMessage: string;
}

export const useUpdateService = (thingId: string, service?: ServiceHistory): {
  saveInProgress: boolean;
  setServiceState: Dispatch<SetStateAction<Undefinable<ServiceHistory>>>;
  updateService(updates: UpdateServiceRequest, options: Partial<UpdateServiceOptions>): Promise<void>;
  createService(updates: CreateServiceRequest, options: Partial<UpdateServiceOptions>): Promise<void>;
  deleteService(options: Partial<UpdateServiceOptions>): Promise<void>;
  serviceState: Undefinable<ServiceHistory>;
} => {
  const { restClient } = useAuthenticated();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);
  const { axios } = useAuthenticated();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [serviceState, setServiceState] = useState(service || undefined);

  const updateService = async (serviceData: UpdateServiceRequest, options: Partial<UpdateServiceOptions>): Promise<void> => {
    const { onFailure, onSuccess } = options;
    if (!service) return;
    try {
      setSaveInProgress(true);
      const updatedService = await restClient.serviceHistory.partialUpdate(thingId, service.serviceHistoryId, serviceData.currentService);
      setServiceState(updatedService);

      if (serviceData.nextService) {
        const nextServiceAction = serviceData.isNextServiceExist ? updateNextService : createNextService;
        await nextServiceAction(serviceData.nextService, updatedService.serviceHistoryId);
      }

      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.update-service.success.hint'), { variant: 'success' });
      onSuccess?.();
    } catch (err) {
      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.update-service.error.hint'), { variant: 'error' });
      onFailure?.();
    } finally {
      setSaveInProgress(false);
    }
  };

  const createService = async (serviceData: CreateServiceRequest, options: Partial<UpdateServiceOptions>): Promise<void> => {
    const { onFailure, onSuccess } = options;
    try {
      setSaveInProgress(true);
      const response = await restClient.serviceHistory.create(thingId, serviceData.currentService);
      setServiceState(response);

      if (serviceData.nextService) {
        await createNextService(serviceData.nextService, response.serviceHistoryId);
      }

      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.create-service.success.hint'), { variant: 'success' });
      onSuccess?.();
    } catch (err) {
      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.create-service.error.hint'), { variant: 'error' });
      onFailure?.();
    } finally {
      setSaveInProgress(false);
    }
  };

  const deleteService = async (options: Partial<UpdateServiceOptions>): Promise<void> => {
    const { onFailure, onSuccess } = options;
    if (!service) return;
    try {
      setSaveInProgress(true);
      const updatedService = await restClient.serviceHistory.delete(thingId, service.serviceHistoryId);
      setServiceState(updatedService);
      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.delete-service.success.hint'), { variant: 'success' });
      onSuccess?.();
    } catch (err) {
      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.delete-service.error.hint'), { variant: 'error' });
      onFailure?.();
    } finally {
      setSaveInProgress(false);
    }
  };

  const updateNextService = async (updates: ServiceHistoryUpdatableProps, serviceHistoryId: string): Promise<void> => {
    try {
      await axios.patch<ServiceHistory>(`/api/v1/next-service/service-history/${serviceHistoryId}`, updates);
    } catch (err) {
      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.update-service.error.hint'), { variant: 'error' });
    }
  };

  const createNextService = async (updates: ServiceHistoryUpdatableProps, serviceHistoryId: string): Promise<void> => {
    try {
      await axios.post<ServiceHistory>(`/api/v1/next-service/service-history/${serviceHistoryId}`, updates);
    } catch (err) {
      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.create-service.error.hint'), { variant: 'error' });
    }
  };

  return {
    serviceState,
    saveInProgress,
    setServiceState,
    updateService,
    createService,
    deleteService,
  };
};

export const useNextServiceQuery = ({ serviceHistoryId }: { serviceHistoryId: string }, enabled: boolean): UseQueryResult<ServiceHistoryUpdatableProps> => {
  const { axios } = useAuthenticated();
  return useQuery({
    queryKey: ['next-service', serviceHistoryId],
    queryFn: async () => {
      const response = await axios.get<ServiceHistoryUpdatableProps>(`/api/v1/next-service/service-history/${serviceHistoryId}`);
      return response.data ?? null;
    },
    enabled,
    retry: false,
  });
};
