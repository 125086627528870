/* eslint-disable react-hooks/exhaustive-deps */
import { Json } from '@eagle/common';
import { UpdateUserRequest, User } from '@eagle/core-data-types';
import { Autocomplete, Card, CardContent, Stack, TextField, Typography, useTheme } from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { useAuthenticated } from '../../auth';
import { Avatar, LoadingButton, LocaleSelect, MiddleSpinner, PageTitle, PhoneInput, PortalFeatureIcons, TextEditor, TimeZoneSelect, useBoolFlag, useStringFlag } from '../../components';
import { PHONE_MIN_LENGTH } from '../../constants';
import { useApiErrorHandler, useDynamicModule, useFetchOneCache, useTitle } from '../../hooks';
import { usePhoneDigits } from '../../hooks/phone-input/use-phone-digits';
import { CacheDataTypes, FeatureIcons, PageAction, SetState } from '../../types';
import { useLocalStorage } from '../../util';
import { ProfilePageSubscriptions } from './profile-page-subscriptions';
import { ProfileTabTypes, UserFieldSection } from './profile-page-types';
import { ProfilePageView } from './profile-page-view';

interface Props {
  actions: PageAction[];
  setRefresh: SetState<Date>;
  tab: ProfileTabTypes;
  userData: User;
}

type OmittedUpdateUserRequest = Omit<UpdateUserRequest, 'userGroups' | 'identities' | 'invitation' | 'email' | 'display' | 'labels' | 'tags'>

const DEFAULT_LANGUAGE_OPTIONS: string[] = ['en', 'id', 'ja', 'ko', 'es_mx', 'zh'];

export const ProfilePageController: FC<Props> = ({ actions, setRefresh, tab, userData }) => {
  useTitle(userData.display);
  const { account, restClient, user } = useAuthenticated();
  const { handleUpdateError } = useApiErrorHandler();
  const { t } = useTranslation(['common', 'l10n']);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const myUserCache = useFetchOneCache(CacheDataTypes.MY_USER);
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);

  const [userState, setUserState] = useState<User>(userData);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [notificationEmailError, setNotificationEmailError] = useState(false);
  const [notificationEmailHelper, setNotificationEmailHelper] = useState('');
  const [saveDisable, setSaveDisable] = useState(true);

  const [storedLanguage, setStoredLanguage] = useLocalStorage<string | null>('portal-language-preference', null);
  const [portalLanguage, setPortalLanguage] = useState<string | null>(() => {
    return storedLanguage ? storedLanguage.replace(/^"|"$/g, '') : null;
  });
  const [initialPortalLanguage, setInitialPortalLanguage] = useState<string | null>(portalLanguage);

  const showPortalLanguage = useBoolFlag('portals-portal-language-feature');
  const portalLanguageOptionsString = useStringFlag('portals-portal-language-options') ?? '';
  const portalLanguageOptions: string[] = portalLanguageOptionsString
    ? portalLanguageOptionsString.split(',').map((lang) => lang.trim())
    : DEFAULT_LANGUAGE_OPTIONS;

  const getLanguageName = (lang: string): string => {
    return t(`l10n:locales.${lang}`);
  };

  const getUserUpdatableProps = ({ localization, notificationChannel }: User): OmittedUpdateUserRequest => ({
    localization: {
      locale: localization?.locale ?? null,
      timezone: localization?.timezone ?? null,
    },
    notificationChannel: {
      api: notificationChannel?.api ?? null,
      email: notificationChannel?.email ?? null,
      sms: (notificationChannel?.sms && notificationChannel?.sms?.length > PHONE_MIN_LENGTH && validator.isMobilePhone(notificationChannel?.sms) && isValidPhoneNumber(notificationChannel?.sms))
        ? notificationChannel?.sms
        : null,
    },
  });

  const handleEmailFieldChange = (value: string): Promise<void> => {
    const isValueValid = value === '' || validator.isEmail(value);
    void handlePropertyChange(UserFieldSection.NOTIFICATION_CHANNEL)({ ...userState.notificationChannel, email: value !== '' ? value : null });
    if (isValueValid) {
      setNotificationEmailHelper('');
      setNotificationEmailError(false);
      return Promise.resolve();
    }
    setNotificationEmailHelper(t('common:common.hint.email'));
    setNotificationEmailError(true);
    return Promise.resolve();
  };

  const handlePropertyChange = (property: string) => (value: Json): void => {
    setUserState({ ...userState, [property]: value });
  };

  const handlePortalLanguageChange = (_event: React.SyntheticEvent, value: string | null): void => {
    setPortalLanguage(value === '' ? null : value);
  };

  const renderAvatarComponent = (): JSX.Element => <Avatar title={user.display} subtitle={account.display} />;

  const renderPageTitle = (): JSX.Element => <PageTitle data-testid='profile-page-title' icon={module?.UserProfileIcon && <module.UserProfileIcon />} title={user.display} />;

  const renderNotificationContent = (): JSX.Element => {
    return (
      <Card data-testid="profile-page-notification-card">
        <CardContent>
          <Stack spacing={2.5} sx={{ mt: 2 }}>
            <Typography variant="h6">{t('common:page.profile.notification-channel.labels')}</Typography>
            <TextEditor
              data-testid="notification-email-input"
              disabled={saveInProgress}
              error={notificationEmailError}
              helperText={notificationEmailHelper}
              label={t('common:page.profile.notification-email.labels')}
              onKeyUp={(value) => handleEmailFieldChange(value)}
              placeholder={userState.email ?? t('common:page.profile.notification-email.hint')}
              size="small"
              value={userState.notificationChannel?.email ?? ''}
            />
            <PhoneInput
              data-testid="sms-input"
              disabled={saveInProgress}
              disableFormatting
              label={t('common:component.properties-element.labels.sms')}
              onChange={(value) => handlePropertyChange(UserFieldSection.NOTIFICATION_CHANNEL)({ ...userState.notificationChannel, sms: value !== '' ? value : null })}
              size="small"
              mobileOnly={true}
              value={userState.notificationChannel?.sms ?? ''}
            />
            <Typography variant="h6">{t('common:page.profile.notification-settings.labels')}</Typography>
            <LocaleSelect
              data-testid="locale-input"
              fieldDisabled={saveInProgress}
              selectedLocale={userState?.localization?.locale ?? ''}
              setSelectedLocale={(value) =>
                handlePropertyChange(UserFieldSection.LOCALIZATION)({
                  ...userState.localization,
                  locale: value !== '' ? value : null,
                })
              }
            />
            <TimeZoneSelect
              data-testid="timezone-input"
              fieldDisabled={saveInProgress}
              selectedTimeZone={userState?.localization?.timezone ?? ''}
              setSelectedTimeZone={(value) =>
                handlePropertyChange(UserFieldSection.LOCALIZATION)({
                  ...userState.localization,
                  timezone: value !== '' ? value : null,
                })
              }
            />
            {showPortalLanguage && (
              <>
                <Typography variant="h6">{t('common:page.profile.portal-language.labels')}</Typography>
                <Autocomplete
                  data-testid="portal-language-input"
                  disableClearable
                  disabled={saveInProgress}
                  value={portalLanguage ?? ''}
                  onChange={handlePortalLanguageChange}
                  options={['', ...portalLanguageOptions]}
                  getOptionLabel={(languageCode: string) => !languageCode.length
                    ? t('common:page.profile.portal-language-default.hint', { language: getLanguageName(window.navigator.language) })
                    : getLanguageName(languageCode)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      data-testid="text-input"
                      label={t('common:page.profile.portal-language.labels')}
                    />
                  )}
                  renderOption={(optionProps, option) => !option.length
                    ? <li data-testid="default-option" {...optionProps}><i>{t('common:page.profile.portal-language-default.hint', { language: getLanguageName(window.navigator.language) })}</i></li>
                    : <li data-testid={`option-${option}`} {...optionProps}>{getLanguageName(option)}</li>
                  }
                  sx={{
                    '& .MuiAutocomplete-input': {
                      color: !portalLanguage ? theme.palette.text.secondary : theme.palette.text.primary,
                      fontStyle: !portalLanguage ? 'italic' : 'normal',
                    },
                  }}
                  fullWidth
                />
                <Typography color="text.secondary" fontStyle="italic" variant="body2">
                  {t('common:page.profile.portal-language.refresh-hint')}
                </Typography>
              </>
            )}
            <LoadingButton
              data-testid="save-button"
              disabled={saveDisable}
              loadingCaption={t('common:common.hint.saving')}
              sx={{ alignSelf: 'end', minWidth: 120 }}
              task={updateUser}
            >
              {t('common:common.action.save')}
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  const renderSubscriptionsContent = (): JSX.Element => {
    return (
      <ProfilePageSubscriptions />
    );
  };

  const updateUser = async (): Promise<void> => {
    try {
      setSaveInProgress(true);
      const updatedUser = await restClient.my.user.partialUpdate(userState._id, getUserUpdatableProps(userState));
      await myUserCache.invalidate(userState._id);
      setRefresh(new Date());
      setUserState(updatedUser);

      if (portalLanguage !== initialPortalLanguage) {
        setInitialPortalLanguage(portalLanguage);
        setStoredLanguage(portalLanguage);

        // Schedule a page reload after a short delay
        setTimeout(() => window.location.reload(), 1000);
      }

      enqueueSnackbar(t('common:common.hint.update-success', { entity: updatedUser.display }), { variant: 'success' });
    } catch (err) {
      handleUpdateError(err, CacheDataTypes.MY_USER, userState.display);
    } finally {
      setSaveInProgress(false);
    }
  };

  const { callingCode } = usePhoneDigits({
    value: userState.notificationChannel?.sms || '',
  });

  const smsChanged = (userData.notificationChannel?.sms ?? '') !== (userState.notificationChannel?.sms ?? '').replace(callingCode, '');
  const emailChanged = userData.notificationChannel?.email !== userState.notificationChannel?.email;

  const smsValidate = useCallback((): boolean => {
    const sms = userState.notificationChannel?.sms ?? '';
    const phoneNumber = sms.replace(callingCode, '');

    if (!!phoneNumber &&
      !(validator.isMobilePhone(sms) && isValidPhoneNumber(sms))
    ) return false;

    return true;
  }, [callingCode, userState.notificationChannel?.sms]);

  const emailValidate = (): boolean => {
    const email = userState.notificationChannel?.email ?? '';

    if (email !== '' && !validator.isEmail(email)) return false;

    return true;
  };

  const usersValidate = (): boolean => !!userState.display?.length;

  useEffect(() => {
    const isPortalLanguageChanged = portalLanguage !== initialPortalLanguage;
    let shouldUpdate = false;

    if (usersValidate()) {
      if (isPortalLanguageChanged) shouldUpdate = true;
      if (JSON.stringify(userData.localization) !== JSON.stringify(userState.localization)) shouldUpdate = true;
      if (JSON.stringify(userData.notificationChannel) !== JSON.stringify(userState.notificationChannel)) {
        shouldUpdate = true;
        if (smsChanged && !smsValidate()) shouldUpdate = false;
        if (emailChanged && !emailValidate()) shouldUpdate = false;
      }
    }
    setSaveDisable(!shouldUpdate);
  }, [emailChanged, smsChanged, smsValidate, portalLanguage, initialPortalLanguage, userData, userState]);

  const tabTypeToRenderFunction: Record<ProfileTabTypes, () => JSX.Element> = {
    [ProfileTabTypes.NOTIFICATIONS]: renderNotificationContent,
    [ProfileTabTypes.SUBSCRIPTIONS]: renderSubscriptionsContent,
  };
  const renderContent = tabTypeToRenderFunction[tab];

  if (!moduleLoaded) return <MiddleSpinner />;

  return (
    <ProfilePageView
      actions={actions}
      renderAvatarComponent={renderAvatarComponent}
      renderContent={renderContent}
      renderPageTitle={renderPageTitle}
    />
  );
};
