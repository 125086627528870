import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MiddleSpinner } from '../../components';
import { T_ONE } from '../../constants';
import { FeatureWithInstancesAndEvents, getEventFeature, getEventLabel, getFeatureInstance, useMyAlertableFeaturesQuery } from '../../util';
import { CreateSubscriptionFields } from './create-subscription.types';

interface Props {
  onActiveBack: () => void;
  onActiveNext: () => void;
  subscription: CreateSubscriptionFields;
  updateAlertFilters: (settings: Partial<CreateSubscriptionFields['alertFilters']>) => void;
}

export const CreateSubscriptionFeatureAlertSettingsV2: FC<Props> = ({ onActiveBack, onActiveNext, updateAlertFilters, subscription }) => {
  const { t } = useTranslation(['admin', 'common']);

  const { data: features, status } = useMyAlertableFeaturesQuery(subscription.alertFilters.thingId);

  const renderBody = (): JSX.Element => {
    if (status === 'pending') {
      return <MiddleSpinner />;
    }

    if (status === 'error') {
      return <Typography>{t('admin:common.hint.unable-to-load-entity', { entity: t('common:common.labels.features') })}</Typography>;
    }

    return (
      <CreateSubscriptionFeatureAlertSettingsV2Inner
        features={features}
        subscription={subscription}
        updateAlertFilters={updateAlertFilters}
      />
    );
  };

  return (
    <Stack spacing={2} data-testid="feature-alert-settings">
      <Typography variant="h5">{t('admin:page.create-subscription.feature-alert-setting.labels')}</Typography>

      {renderBody()}

      <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', my: 1 }}>
        <Button
          onClick={onActiveBack}
          variant="outlined"
          data-testid="button-back"
        >
          {t('common:common.action.back')}
        </Button>
        <Button
          disabled={status !== 'success'}
          onClick={onActiveNext}
          variant="contained"
          data-testid="button-next"
        >
          {t('common:common.action.next')}
        </Button>
      </Stack>
    </Stack>
  );
};

interface CreateSubscriptionFeatureAlertSettingsV2InnerProps extends Pick<Props, 'subscription' | 'updateAlertFilters'> {
  features: FeatureWithInstancesAndEvents[];
}

const CreateSubscriptionFeatureAlertSettingsV2Inner: FC<CreateSubscriptionFeatureAlertSettingsV2InnerProps> = ({ subscription, updateAlertFilters, features }) => {
  const { t } = useTranslation(['admin', 'common', 'terms']);

  const selectedFeature = features.find(({ feature }) => subscription.alertFilters.feature === feature);
  const hasInstances = selectedFeature && selectedFeature.instances.length > 0;
  const isAlertTypeDisabled = !selectedFeature || (hasInstances && !subscription.alertFilters.featureInstance);

  useEffect(() => {
    if (subscription.alertFilters.feature) {
      if (!features.find(({ feature }) => feature === subscription.alertFilters.feature)) {
        updateAlertFilters({
          feature: null,
          featureInstance: null,
          alertTypeId: null,
        });
      }
    }
  }, [features, subscription.alertFilters, updateAlertFilters]);

  return (
    <Stack spacing={2}>
      <Autocomplete
        data-testid="feature-select"
        disablePortal
        getOptionLabel={(option) => getEventFeature(option)}
        noOptionsText={t('common:common.hint.list.no-results')}
        onChange={(_, value) => {
          updateAlertFilters({ feature: value, alertTypeId: null, featureInstance: null });
        }}
        options={features.map(({ feature }) => feature)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('common:terms.feature')}
            variant="outlined"
          />
        )}
        size="small"
        value={subscription.alertFilters.feature}
      />
      {hasInstances && (
        <Autocomplete
          data-testid="feature-instance-select"
          disablePortal
          getOptionLabel={(option) => getFeatureInstance(`${selectedFeature.feature}/${option}`) ?? option}
          noOptionsText={t('common:common.hint.list.no-results')}
          onChange={(_, value) => {
            updateAlertFilters({
              featureInstance: value,
              ...(!value && { alertTypeId: null }),
            });
          }}
          options={selectedFeature.instances}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('common:terms.instance', { count: T_ONE })}
              variant="outlined"
            />
          )}
          size="small"
          value={subscription.alertFilters.featureInstance}
        />
      )}
      <Autocomplete
        data-testid="alert-type-select"
        disablePortal
        getOptionLabel={(option) => selectedFeature ? getEventLabel(selectedFeature.feature, option) : option}
        noOptionsText={t('common:common.hint.list.no-results')}
        disabled={isAlertTypeDisabled}
        onChange={(_, value) => {
          updateAlertFilters({ alertTypeId: value });
        }}
        options={selectedFeature?.alertableEvents ?? []}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('common:terms.alert-type')}
            variant="outlined"
          />
        )}
        size="small"
        value={subscription.alertFilters.alertTypeId}
      />
    </Stack>
  );
};
