/* eslint-disable react-hooks/exhaustive-deps */
import { Change, PaginatedResponse, Pagination, UpdatableBase } from '@eagle/api-types';
import { RoleFunction } from '@eagle/common';
import { Account } from '@eagle/core-data-types';
import axiosStatic from 'axios';
import { useSnackbar } from 'notistack';
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { catchError, finalize, from, of, Subject, switchMap, tap } from 'rxjs';
import { useAuthenticated } from '../../auth';
import { T_ONE } from '../../constants';
import { useApiErrorHandler, useObservable } from '../../hooks';
import { Query } from '../../pages/list/types';
import { SearchProvider, useSearch } from '../../pages/list/use-search';
import { CacheDataTypes, SetState, Undefinable } from '../../types';
import { FILTER_OUT, useHasAuthorization } from '../../util';
import { FindItemsDeferredPaginatedResponse } from '../alerts-table';
import { ErrorMessage } from '../error-message';
import { View } from './view';

const SYSTEM_ADMIN_ROLE = [RoleFunction.SYSTEM_ADMINISTRATOR];
export type EntityType = typeof CacheDataTypes[keyof typeof CacheDataTypes];
export type EntityTypeApiUrl = `/api/v1/${EntityType}` | `/api/v1/my/${EntityType}`

export interface EntityManagementData {
  apiUrl?: EntityTypeApiUrl;
  count?: number;
  entityKey: string;
  entityType: EntityType;
}

export interface EntityTypeBase extends UpdatableBase {
  _id: string;
  accountBinding: string[];
  deleted: Change;
  display: string;
  shared: boolean;
}

export interface AddSharedDialog {
  apiUrl?: string;
  entityType?: EntityType;
  open: boolean;
}

export interface EntityManagementProps {
  account?: Account;
  apiUrls: [EntityTypeApiUrl];
  data: EntityManagementData[];
  'data-testid'?: string;
  disableAddShare?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onAddShare?: (entityType: EntityType) => void;
  renderRow: (data: EntityTypeBase, type: EntityType, index: number, isLoading: boolean, removeEntity: (item: EntityTypeBase, type: CacheDataTypes) => void) => JSX.Element;
  secondaryText?: string;
  tabEntityData?: EntityTypeBase[][];
  title?: string;
  readOnly?: boolean;
}

interface InternalControllerProps extends EntityManagementProps {
  entityData: EntityTypeBase[];
  handleChange: (_: SyntheticEvent, newValue: number) => void;
  index: number;
  onQueryChanged: (_: Query, { skip }: Pagination) => FindItemsDeferredPaginatedResponse<EntityTypeBase>;
  setEntityData: SetState<EntityTypeBase[]>;
}
export interface EntityManagementCardProps extends Omit<EntityManagementProps, 'apiUrls'> {
  apiUri: EntityTypeApiUrl;
  addShareDialog: AddSharedDialog;
  apiData: Undefinable<PaginatedResponse<EntityTypeBase>>;
  assignEntityType: (entity: EntityTypeBase) => Promise<void>;
  assignErrorToast: (entity: string) => void;
  deleteState: DeleteState;
  entityData: EntityTypeBase[];
  handleChange: (event: SyntheticEvent, index: number) => void;
  index: number;
  removeEntity: (item: Undefinable<EntityTypeBase>, type: Undefinable<CacheDataTypes>) => void;
  setAddShareDialog: React.Dispatch<React.SetStateAction<AddSharedDialog>>;
  setDeleteState: (state: DeleteState) => void;
}

interface DeleteState {
  open: boolean;
  loading: boolean;
  type?: CacheDataTypes;
  item?: EntityTypeBase;
}

const DEFAULT_DELETE_STATE: DeleteState = {
  open: false,
  loading: false,
};

const PAGINATION_LIMIT = 3;

const InternalEntityManagementCardController: FC<InternalControllerProps> = ({
  account,
  apiUrls,
  data,
  entityData,
  index,
  onQueryChanged,
  setEntityData,
  ...otherProps
}) => {
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();
  const { axios } = useAuthenticated();
  const { handleUpdateError } = useApiErrorHandler();
  const [deleteState, setDeleteState] = useState(DEFAULT_DELETE_STATE);
  const [addShareDialog, setAddShareDialog] = useState<AddSharedDialog>({ open: false });
  const { hasAuthorization } = useHasAuthorization();
  const hasSysAdminPermissions = hasAuthorization(SYSTEM_ADMIN_ROLE);
  const {
    filters,
    pagination,
    setIsLoading,
    setPagination,
    setResult,
    text,
    setText,
  } = useSearch();

  const removeEntity = async (item: Undefinable<EntityTypeBase>, type: Undefinable<CacheDataTypes>): Promise<void> => {
    if (!type || !item) return;
    const entity = t(data[index].entityKey, { count: T_ONE });
    try {
      setDeleteState({ ...deleteState, loading: true });
      const filteredAccountBinding = item.accountBinding.filter((binding) => binding !== account?._id);
      await axios.patch<EntityTypeBase[]>(`/api/v1/${type}/${item._id}`, { accountBinding: filteredAccountBinding })
        .finally(() => {
          setText('');
          setEntityData((previous: EntityTypeBase[]) => previous?.filter(({ _id }) => _id !== item._id));
          enqueueSnackbar(t('common:component.remove-entity-type-dialog.hint.success', { accountDisplay: account?.display, entityType: entity }), { variant: 'success' });
          setDeleteState(DEFAULT_DELETE_STATE);
        });
    } catch (err) {
      handleUpdateError(
        err,
        entity,
        undefined,
        t('common:component.remove-entity-type-dialog.hint.remove-failure', { accountDisplay: account?.display, entityType: entity }),
      );
    }
  };

  const onError = (error: Error): void => {
    enqueueSnackbar(<ErrorMessage error={error} />, { variant: 'error' });
  };

  const assignEntityType = async (entity: EntityTypeBase): Promise<void> => {
    if (!addShareDialog.entityType || !account || !hasSysAdminPermissions || entity.accountBinding === null) throw new Error(entity.display);
    if (!entity.shared && entity.accountBinding.length > 0) throw new Error(entity.display);
    if (entity.accountBinding.some((id) => id === account?._id)) throw new Error(entity.display);
    try {
      await axios.patch(`/api/v1/${addShareDialog.entityType}/${entity._id}`, { accountBinding: [...entity.accountBinding, account._id] });
      setEntityData((previous: EntityTypeBase[] | undefined) => [...(previous ?? []), entity]);
    } catch {
      throw new Error(entity.display);
    }
    return Promise.resolve();
  };

  const { handleQueryChanged, observable } = useMemo(() => {
    const subject = new Subject<Query>();

    return {
      handleQueryChanged: (query: Query): void => subject.next(query),
      observable: subject.pipe(
        tap(() => setIsLoading(true)),
        switchMap((query: Query) => {
          if (!data.length) return of(undefined);
          const deferred = onQueryChanged(query, pagination);
          if (!deferred) return of(undefined);
          let complete = false;

          return from(deferred.promise).pipe(
            tap(({ count, items }) => {
              setResult({ matchCount: count ?? 0, itemCount: items.length });
              complete = true;
            }),
            catchError((error: Error) => {
              onError(error);
              return of(undefined);
            }),
            finalize(() => complete || deferred.cancel()),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, [onQueryChanged, pagination, data, index]);

  const apiData = useObservable(observable);

  useEffect(() => {
    handleQueryChanged({ filters, pagination, search: text });
  }, [entityData, filters, pagination, text]);

  useEffect(() => {
    setPagination({ limit: PAGINATION_LIMIT, skip: 0 });
  }, [index]);

  return (
    <View
      account={account}
      addShareDialog={addShareDialog}
      apiData={apiData}
      apiUri={apiUrls[index]}
      assignEntityType={assignEntityType}
      assignErrorToast={(entity: string) => enqueueSnackbar(t('common:component.assign-dialog.hint.error', { name: account?.display, entity }), { variant: 'error' })}
      data={data}
      deleteState={deleteState}
      entityData={entityData}
      index={index}
      removeEntity={removeEntity}
      setAddShareDialog={setAddShareDialog}
      setDeleteState={setDeleteState}
      {...otherProps}
    />
  );
};

export const EntityManagementCardController: FC<EntityManagementProps> = ({ apiUrls, isLoading, tabEntityData, ...props }) => {
  const [index, setIndex] = useState(0);
  const [entityData, setEntityData] = useState<EntityTypeBase[]>([]);
  const [data, setData] = useState<Undefinable<EntityTypeBase[][]>>(tabEntityData);
  const { axios } = useAuthenticated();
  const handleChange = (_: SyntheticEvent, newValue: number): void => setIndex(newValue);

  const onQueryChanged = (_: Query, { skip }: Pagination): FindItemsDeferredPaginatedResponse<EntityTypeBase> => {
    const cancelToken = axiosStatic.CancelToken.source();
    if (isLoading) {
      return {
        cancel: () => { },
        promise: Promise.resolve({ count: 0, hasMore: false, items: [] }),
      };
    }

    return {
      cancel: () => cancelToken.cancel(),
      promise: data
        ? new Promise((resolve) => {
          const items = entityData.slice(skip, skip + PAGINATION_LIMIT);
          const count = entityData.length;
          resolve({ count, hasMore: count > PAGINATION_LIMIT, items });
        })
        : axios.get<EntityTypeBase[]>(apiUrls[index], {
          cancelToken: cancelToken.token,
          params: {
            filter: { accountBinding: { '$in': [props.account?._id] }, ...FILTER_OUT.deleted },
            limit: PAGINATION_LIMIT,
            skip,
            sort: { display: 'asc' },
          },
        }).then((response) => {
          const headers = response.headers as Record<string, any>;
          const count = parseInt(headers['x-match-count'] as string, 10);
          return {
            count,
            hasMore: count >= response.data.length,
            items: response.data,
          };
        }),
    };
  };

  useEffect(() => {
    if (!data || !props.account) return;
    setEntityData(data[index]);
  }, [data, props.account, index]);

  useEffect(() => {
    setData((prev) => {
      if (!prev) return prev;
      prev[index] = entityData;
      return prev;
    });
  }, [entityData, index]);

  useEffect(() => setData(tabEntityData), [tabEntityData]);

  return (
    <SearchProvider dataKey="entity-management-card" pagination={{ limit: PAGINATION_LIMIT, skip: 0 }}>
      <InternalEntityManagementCardController
        apiUrls={apiUrls}
        entityData={entityData}
        handleChange={handleChange}
        index={index}
        isLoading={isLoading}
        onQueryChanged={onQueryChanged}
        setEntityData={setEntityData}
        {...props}
      />
    </SearchProvider>
  );
};
