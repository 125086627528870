import { AlertFilters, Group, Person, Recipient, Subscription, Thing, User, UserGroup } from '@eagle/core-data-types';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { CommonEntity, Nullable } from '../../types';

export interface Props {
  isProfilePage?: boolean;
  subscription?: Subscription;
}

export interface NotificationRecipientCardProps {
  isProfilePage: boolean;
  fieldDisabled?: boolean;
  getRecipientState: (recipient: Recipient) => void;
  recipient: Recipient;
  setRecipientIsValid: (disable: boolean) => void;
}

export type RecipientUnion = User | UserGroup;

export interface RadioButtonOption {
  label: string | ReactNode;
  value: RadioButtons;
}

export interface NotificationRecipientViewProps {
  channelDetailHelper: string;
  contactDetail: Nullable<string>;
  contactOptions: RadioButtonOption[];
  currentUser?: User;
  defaultOptions: RadioButtonOption[];
  fieldDisabled?: boolean;
  handleEmailFieldChange: (value: string) => Promise<void>;
  handleSelectChange: (propertyPath: keyof NotificationRecipientState, value: string) => void;
  isChannelDetailInvalid: boolean;
  isContactDetailFieldVisible: boolean;
  isContactOptionsVisible: boolean;
  isProfilePage: boolean;
  notificationGroupOptions: RadioButtonOption[];
  recipients: RecipientUnion[];
  recipientsError?: boolean;
  recipientsPending: boolean;
  recipientState: NotificationRecipientState;
  selectedLocale: string;
  selectedTimezone: string;
  setChannelDetailHelper: (value: SetStateAction<string>) => void;
  setContactDetail: (value: SetStateAction<Nullable<string>>) => void;
  setIsChannelDetailInvalid: (value: SetStateAction<boolean>) => void;
  setRecipientState: (value: SetStateAction<NotificationRecipientState>) => void;
  setSelectedLocale: Dispatch<SetStateAction<string>>;
  setSelectedTimezone: Dispatch<SetStateAction<string>>;
  userOptions: RadioButtonOption[];
}

export enum RadioButtons {
  ALL_THING_PEOPLE = 'all-things-and-people',
  CUSTOM = 'custom',
  DEFAULT = 'default',
  EMAIL = 'email',
  GROUP = 'group',
  INDIVIDUAL = 'individual',
  OTHER = 'other',
  PERSON = 'person',
  SMS = 'sms',
  THING = 'thing',
  USER = 'user',
}

export type ContactMethodRadioButtons = RadioButtons.EMAIL | RadioButtons.SMS;
export type DefaultCustomRadioButtons = RadioButtons.DEFAULT | RadioButtons.CUSTOM;
export type NotificationGroupRadioButtons = RadioButtons.GROUP | RadioButtons.INDIVIDUAL;
export type UserRadioButtons = RadioButtons.OTHER | RadioButtons.USER;

export interface NotificationRecipientState {
  contactMethod: ContactMethodRadioButtons;
  defaultOrCustomContact: DefaultCustomRadioButtons;
  notificationGroup: NotificationGroupRadioButtons;
  selectedRecipient: Nullable<string>;
  user: UserRadioButtons;
}

export interface FiltersRadioGroupState<T> {
  individualOrGroup: T;
  thingsOrPeople: T;
}

export interface EntityDetail {
  alertFilterId?: Nullable<string>;
  apiUrl?: string;
  key?: EntityTypes;
  label?: string;
  noResults?: string;
  options?: {
    [EntityTypes.THING]?: Thing[];
    [EntityTypes.PERSON]?: Person[];
    [EntityTypes.GROUP]?: Group[];
  };
}

export enum EntityTypes {
  GROUP = 'group',
  PERSON = 'person',
  THING = 'thing',
}

export interface EntityState { person: CommonEntity<Nullable<string>>; thing: CommonEntity<Nullable<string>>; group: CommonEntity<Nullable<string>> }

export interface EntityAutocompleteProps {
  data: EntityDetail;
  fieldDisabled?: boolean;
  onOptionChanged: (value: EntityState) => void;
  selectedOption: EntityState;
  size?: 'small' | 'medium';
  'data-testid'?: string;
}

export interface ThingPeopleFiltersCardProps {
  alertFilters: AlertFilters;
  fieldDisabled?: boolean;
  getThingsPeopleState: (state: EntityState) => void;
  setThingPeopleIsValid: (disable: boolean) => void;
}

export interface Settings {
  alertTypeId: Nullable<string>;
  feature: Nullable<string>;
  featureTypeIdPrefix: Nullable<string>;
}

export interface SettingsWithFeatureInstance extends Settings {
  featureInstance: Nullable<string>;
}

export interface FeatureAlertSettingsCardProps {
  alertFilters: AlertFilters;
  fieldDisabled: boolean;
  getSettingsState: (state: Settings) => void;
}

export interface Contact {
  email: Nullable<string>;
  sms: Nullable<string>;
}
