import { Operator } from '@jexop/core';
import { isNumber, isString } from 'lodash';
import { Nullable } from '../types';
import { ifTranslationExists } from '../util';

const DIRECTION_NAMES = [
  'north', 'north-north-east', 'north-east', 'east-north-east',
  'east', 'east-south-east', 'south-east', 'south-south-east',
  'south', 'south-south-west', 'south-west', 'west-south-west',
  'west', 'west-north-west', 'north-west', 'north-north-west',
] as const;

export const cardinalDirection: Operator<unknown> = ({ angle, fullLabel }): Nullable<string> => {
  const angleNumeric = isString(angle) ? Number(angle) : isNumber(angle) ? angle : null;
  const fullLabelString = fullLabel === true ? 'full' : 'abbr';

  if (!angleNumeric) {
    return null;
  }

  const directions = DIRECTION_NAMES.map((direction) => ifTranslationExists(`common:labels.direction.${direction}.${fullLabelString}`, direction));

  const section: number = Math.floor(angleNumeric / 22.5 + 0.5);

  return directions[section % 16];
};
