import { Recipient } from '@eagle/core-data-types';
import { FC } from 'react';
import { CreateSubscriptionFeatureAlertSettings } from './create-subscription-feature-alert-settings';
import { CreateSubscriptionFeatureAlertSettingsV2 } from './create-subscription-feature-alert-settings-v2';
import { CreateSubscriptionLayout } from './create-subscription-layout';
import { CreateSubscriptionNotificationRecipient } from './create-subscription-notification-recipient';
import { CreateSubscriptionThingPeopleFilters } from './create-subscription-thing-people-filters';
import { CreateSubscriptionFields } from './create-subscription.types';

interface Props {
  activeStep: number;
  createInProgress: boolean;
  createSubscription: () => Promise<void>;
  isProfilePage: boolean;
  isSubscriptionsV2Enabled: boolean;
  onActiveBack: () => void;
  onActiveNext: () => void;
  subscription: CreateSubscriptionFields;
  updateAlertFilters: (values: Partial<CreateSubscriptionFields['alertFilters']>) => void;
  updateRecipient: (recipient: Recipient) => void;
}

export const CreateSubscriptionView: FC<Props> = ({
  activeStep,
  createInProgress,
  createSubscription,
  isProfilePage,
  isSubscriptionsV2Enabled,
  onActiveBack,
  onActiveNext,
  subscription,
  updateAlertFilters,
  updateRecipient,
}) => {
  return (
    <CreateSubscriptionLayout
      activeStep={activeStep}
      featureAlertSettings={
        isSubscriptionsV2Enabled ?
          <CreateSubscriptionFeatureAlertSettingsV2
            onActiveBack={onActiveBack}
            onActiveNext={onActiveNext}
            updateAlertFilters={updateAlertFilters}
            subscription={subscription}
          /> :
          <CreateSubscriptionFeatureAlertSettings
            onActiveBack={onActiveBack}
            onActiveNext={onActiveNext}
            updateFeatureAlertSettings={updateAlertFilters}
          />
      }
      notificationRecipient={
        <CreateSubscriptionNotificationRecipient
          createInProgress={createInProgress}
          createSubscription={createSubscription}
          isProfilePage={isProfilePage}
          onActiveBack={onActiveBack}
          updateNotificationRecipient={updateRecipient}
        />
      }
      thingPeopleFilters={
        <CreateSubscriptionThingPeopleFilters
          onActiveNext={onActiveNext}
          updateThingPeopleFilters={updateAlertFilters}
        />
      }
    />
  );
};
