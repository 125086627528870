import { Operator } from '@jexop/core';
import { isObject, isString } from 'lodash';
import { I18n } from '../providers';
import { Nullable } from '../types';

export const translate: Operator<string> = ({ translation, options, ...replace }, _): Nullable<string> => {
  if (!isString(translation)) {
    return null;
  }
  const opts = !isObject(options) ? {} : options;
  const keyReplace = !isObject(replace) ? {} : replace;

  const translated = I18n.getInstance().t(translation, { ...opts, ...keyReplace });

  const parser = new DOMParser();
  const parsed = parser.parseFromString(translated, 'text/html');

  return parsed.body.textContent;
};
