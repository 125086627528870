import { QueryArgs } from '@eagle/api-types';
import { LastThingEvent } from '@eagle/core-data-types';
import { FeatureTypes, TrackingEventTypes } from '@eagle/data-function-types';
import { EthingsRestClient } from '@eagle/ethings-rest-client';
import { DateTime } from 'luxon';
import { Maybe, Undefinable } from '../types';

export interface SignedUrl {
  signedUrl: Maybe<string>;
}

export const getLastThingEvent = async (restClient: EthingsRestClient, thingId: string, params?: Partial<QueryArgs>): Promise<Undefinable<LastThingEvent>> => {
  const result = await restClient.lastThingEvent.getWithThing(thingId, {
    limit: 1,
    sort: '-occurred',
    ...(params || {}),
  });
  return result.items[0];
};

export const getLastThingLocation = async (restClient: EthingsRestClient, thingId: string): Promise<Undefinable<LastThingEvent>> => {
  const result = await restClient.lastThingEvent.getWithThing(thingId, {
    filter: {
      featureTypeId: FeatureTypes.TRACKING_V0,
      feature: 'tracking',
      eventTypeId: TrackingEventTypes.LOCATION_UPDATE,
    },
    limit: 1,
    sort: '-occurred',
  });
  return result.items[0];
};

export const getSignedUrl = async (restClient: EthingsRestClient, url?: URL | string): Promise<SignedUrl> => {
  if (!url) return { signedUrl: null };
  const getNewSigned = async (): Promise<SignedUrl> => restClient.signedUrl.readAccess({ url: typeof url === 'string' ? url : url.toString() });
  const preSignedUrl = (): Promise<SignedUrl> => {
    const { searchParams } = new URL(url);
    const expires = DateTime.fromMillis(parseInt(searchParams.get('Expires') ?? '0', 10) * 1000);
    if (DateTime.now() > expires) return getNewSigned();
    return Promise.resolve({ signedUrl: url.toString() });
  };

  const data = new URL(url).search
    ? await preSignedUrl()
    : await getNewSigned();
  return data;
};
