import { Interval } from 'luxon';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { AppliedFilter } from '../../components';
import { RangeSelectValues } from '../../components/date-time-range-picker';
import { Pagination as PaginationData } from '../../components/entity-search/types';
import { SerializationOptions, useHistoryState } from '../../hooks/use-history-state';
import { Undefinable } from '../../types';
import { DateRange } from './types';

export interface SearchContext extends SearchData {
  disableFilter: boolean;
  disableFilters: () => void;
  enableFilters: () => void;
  isLoading: boolean;
  result?: Result;
  setDateRange: (value: DateRange) => unknown;
  setFilters: (value: AppliedFilter[]) => unknown;
  setGeofencePagination: (value: PaginationData) => unknown;
  setHighlightIndex: (value?: number) => unknown;
  setIsLoading: (value: boolean) => unknown;
  setPagination: (value: PaginationData) => unknown;
  setResult: (value?: Result) => unknown;
  setSavedSelection: (value: RangeSelectValues['key']) => unknown;
  setShowDeleted: (value: boolean) => unknown;
  setText: (value: string) => unknown;
}

interface SearchData {
  dateRange?: DateRange;
  filterDisabled: boolean;
  filters: AppliedFilter[];
  geofencePagination: PaginationData;
  highlightIndex?: number;
  pagination: PaginationData;
  savedSelection: RangeSelectValues['key'];
  showDeleted: boolean;
  text: string;
}

interface SearchProvider extends PropsWithChildren {
  dataKey?: string;
  geofencePagination?: PaginationData;
  pagination?: PaginationData;
}

interface SearchDataSerialized extends Omit<SearchData, 'dateRange'> {
  dateRange?: string;
}

export interface Result {
  itemCount: number;
  matchCount: number;
}

export const searchContext = createContext<Undefinable<SearchContext>>(undefined);

const serializationOptions: SerializationOptions<SearchData, SearchDataSerialized> = {
  fromSerializable: (value) => {
    const { dateRange, ...rest } = value;
    const interval = dateRange ? Interval.fromISO(dateRange) : undefined;
    return ({
      ...rest,
      dateRange: interval
        ? {
          startTime: interval.start,
          endTime: interval.end,
        }
        : undefined,
    });
  },
  toSerializable: (value) => ({
    ...value,
    dateRange: value.dateRange
      ? Interval.fromDateTimes(value.dateRange.startTime, value.dateRange.endTime).toISO()
      : undefined,
  }),
};

export const SearchProvider: FC<SearchProvider> = ({
  children,
  dataKey = 'data',
  geofencePagination = { limit: 25, skip: 0 },
  pagination = { limit: 25, skip: 0 },
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [disableFilter, setDisableFilter] = useState(false);
  const [savedFilters, setSavedFilters] = useState<AppliedFilter[]>([]);
  const [result, setResult] = useState<Result>();
  const [data, setData] = useHistoryState<SearchData, SearchDataSerialized>(
    dataKey,
    {
      dateRange: undefined,
      filterDisabled: false,
      filters: [],
      geofencePagination,
      highlightIndex: undefined,
      pagination,
      savedSelection: RangeSelectValues.LAST_12_HOURS.key,
      showDeleted: false,
      text: '',
    },
    true,
    serializationOptions,
  );

  const setFilters = (value: AppliedFilter[]): void => {
    setSavedFilters(disableFilter ? [] : value);
    setData((prev) => ({
      ...prev,
      highlightIndex: undefined,
      pagination: { limit: prev.pagination.limit, skip: 0 },
      filters: disableFilter ? [] : value,
    }));
  };

  const setShowDeleted = (value: boolean): void => {
    setData((prev) => ({
      ...prev,
      highlightIndex: undefined,
      pagination: { limit: prev.pagination.limit, skip: 0 },
      showDeleted: value,
    }));
  };

  const setHighlightIndex = (value?: number): void => setData({
    ...data,
    highlightIndex: value,
  });

  const setPagination = (value: PaginationData): void => setData({
    ...data,
    highlightIndex: undefined,
    pagination: value,
  });

  const setGeofencePagination = (value: PaginationData): void => setData({
    ...data,
    geofencePagination: value,
    highlightIndex: undefined,
  });

  const setText = useCallback((value: string) => setData((data) => ({
    ...data,
    highlightIndex: undefined,
    pagination: { limit: data.pagination.limit, skip: 0 },
    text: value,
  })), [setData]);

  const setDateRange = (value: DateRange): void => setData({
    ...data,
    highlightIndex: undefined,
    pagination: { limit: data.pagination.limit, skip: 0 },
    dateRange: value,
  });

  const setSavedSelection = (value: RangeSelectValues['key']): void => setData({
    ...data,
    highlightIndex: undefined,
    pagination: { limit: data.pagination.limit, skip: 0 },
    savedSelection: value,
  });

  const disableFilters = (): void => {
    setDisableFilter(true);
    setData({
      ...data,
      filters: [],
    });
  };

  const enableFilters = (): void => {
    setDisableFilter(false);
    setData({
      ...data,
      filters: savedFilters,
    });
  };

  return (
    <searchContext.Provider
      value={{
        ...data,
        disableFilter,
        disableFilters,
        enableFilters,
        isLoading,
        result,
        setDateRange,
        setFilters,
        setGeofencePagination,
        setHighlightIndex,
        setIsLoading,
        setPagination,
        setResult,
        setSavedSelection,
        setShowDeleted,
        setText,
      }}
    >
      {children}
    </searchContext.Provider>
  );
};

export const useSearch = (): SearchContext => {
  const data = useContext(searchContext);
  if (!data) throw new Error('Missing SearchProvider in tree above useSearch');
  return data;
};

export const useOptionalSearch = (): Undefinable<SearchContext> => {
  const data = useContext(searchContext);
  return data;
};
