import { captureException } from '@sentry/react';
import { getPortalLocale } from './locale';

export type UnitDisplay = 'long' | 'short' | 'narrow';

export const formatDistanceString = (meters: number, preferredUnit = 'kilometer', unitDisplay: UnitDisplay = 'long'): string => {
  if (meters < 0) {
    throw new Error('Distance cannot be negative');
  }

  const language = getPortalLocale().replace('_', '-');

  const unit = preferredUnit || 'kilometer';

  const conversionFactor = unit === 'mile' ? 0.000621371 : 0.001;

  const convertedDistance = meters * conversionFactor;

  try {
    const formatter = new Intl.NumberFormat(language, { style: 'unit', unit, unitDisplay, maximumFractionDigits: convertedDistance < 1 ? 2 : 0});
    return formatter.format(convertedDistance);
  } catch (error) {
    captureException(error);
    return `${convertedDistance} ${unit}`;
  }
};
