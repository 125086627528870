/* eslint-disable react-hooks/exhaustive-deps */
import { Group, Person, Thing } from '@eagle/core-data-types';
import { Button, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { MiddleSpinner, RadioButtonSet } from '../../components';
import { T_ONE } from '../../constants';
import { usePromise } from '../../hooks';
import { FILTER_OUT } from '../../util';
import { defaultEntity, EntityAutocomplete } from '../subscription/entity-autocomplete';
import { EntityLookup } from '../subscription/entity-lookup';
import { EntityDetail, EntityState, EntityTypes, FiltersRadioGroupState, RadioButtons } from '../subscription/subscription-detail.types';
import { ThingPersonFilterIds } from './create-subscription.types';

interface Props {
  onActiveNext: () => void;
  updateThingPeopleFilters: (ids: ThingPersonFilterIds) => void;
}

export const CreateSubscriptionThingPeopleFilters: FC<Props> = ({ onActiveNext, updateThingPeopleFilters }) => {
  const { t } = useTranslation(['admin', 'common', 'terms']);
  const { restClient } = useAuthenticated();
  const [nextDisabled, setNextDisabled] = useState(false);

  const [selectedOption, setSelectedOption] = useState<EntityState>({
    group: defaultEntity,
    person: defaultEntity,
    thing: defaultEntity,
  });

  const thingsOrPeopleOptions = [
    {
      label: t('admin:page.subscriptions-list.all-things-people.label'),
      value: RadioButtons.ALL_THING_PEOPLE,
    },
    {
      label: t('terms:thing', { count: T_ONE }),
      value: RadioButtons.THING,
    },
    {
      label: t('terms:person', { count: T_ONE }),
      value: RadioButtons.PERSON,
    },
  ];

  const individualOrGroupsOptions = [
    {
      label: t('admin:common.labels.individual'),
      value: RadioButtons.INDIVIDUAL,
    },
    {
      label: t('common:terms.group', { count: T_ONE }),
      value: RadioButtons.GROUP,
    },
  ];

  const [selectedRadio, setSelectedRadio] = useState<FiltersRadioGroupState<RadioButtons>>({
    thingsOrPeople: RadioButtons.ALL_THING_PEOPLE,
    individualOrGroup: RadioButtons.INDIVIDUAL,
  });

  const handleSelectedRadio = (propertyPath: keyof FiltersRadioGroupState<RadioButtons>, value: string): void => {
    setSelectedRadio((prev) => {
      return {
        ...prev,
        [propertyPath]: value,
      };
    });
  };

  const handleSelectedEntity = (entity: Thing | Person, key: string): void => {
    setSelectedOption((prev) => {
      return {
        ...prev,
        [key]: { _id: entity._id, display: entity.display },
      };
    });
  };

  const [groups, groupsError, groupsState] = usePromise<Group[]>(
    async () => {
      return restClient.group.getAll({
        filter: FILTER_OUT.deleted,
        sort: JSON.stringify({ display: 'asc' }),
      });
    },
    [restClient],
  );

  const computeSelectedRadio = (): EntityDetail => {
    if (selectedRadio.individualOrGroup === RadioButtons.INDIVIDUAL) {
      if (selectedRadio.thingsOrPeople === RadioButtons.THING) {
        return {
          key: EntityTypes.THING,
          alertFilterId: selectedOption.thing?._id,
          apiUrl: '/api/v1/thing',
          label: t('common:component.filter.labels.select-a-thing'),
          noResults: t('common:common.hint.list.no-results'),
        };
      }
      if (selectedRadio.thingsOrPeople === RadioButtons.PERSON) {
        return {
          key: EntityTypes.PERSON,
          alertFilterId: selectedOption.person?._id,
          apiUrl: '/api/v1/person',
          label: t('common:component.filter.labels.select-a-person'),
          noResults: t('common:common.hint.list.no-results'),
        };
      }
    }
    if (selectedRadio.individualOrGroup === RadioButtons.GROUP) {
      return {
        key: EntityTypes.GROUP,
        alertFilterId: selectedOption.group?._id,
        label: t('common:component.filter.labels.select-a-group'),
        options: { [EntityTypes.GROUP]: groups },
      };
    }
    return {};
  };

  const entityData = computeSelectedRadio();

  useEffect(() => {
    updateThingPeopleFilters({
      groupId: selectedOption.group?._id ?? null,
      personId: selectedOption.person?._id ?? null,
      thingId: selectedOption.thing?._id ?? null,
    });
  }, [selectedOption]);

  const isInvalid = (): boolean => {
    if (selectedRadio.thingsOrPeople === RadioButtons.ALL_THING_PEOPLE) return false;
    if (selectedRadio.individualOrGroup === RadioButtons.GROUP) return (!selectedOption.group?._id);
    return ((selectedRadio.thingsOrPeople === RadioButtons.THING && !selectedOption.thing?._id)
      || (selectedRadio.thingsOrPeople === RadioButtons.PERSON && !selectedOption.person?._id));
  };

  useEffect(() => {
    setNextDisabled(isInvalid());
  }, [selectedRadio, selectedOption]);

  if (groupsState === 'pending') return <MiddleSpinner />;

  if (groupsError) {
    return (
      <Stack spacing={2}>
        <Typography variant="h5">{t('admin:page.subscriptions-list.all-things-people.label')}</Typography>
        <Typography>{t('admin:page.subscriptions-list.all-things-people.hint')}</Typography>
      </Stack>
    );
  }

  return (
    <Stack spacing={2} data-testid="thing-people-filter">
      <Typography variant="h5">
        {t('admin:page.create-subscription.thing-people.labels')}
      </Typography>
      <Stack>
        <Typography variant="subtitle2">{t('admin:page.subscription-detail.alerts-source.label')}</Typography>
        <RadioButtonSet
          data-testid="alerts-source"
          options={thingsOrPeopleOptions}
          selected={selectedRadio.thingsOrPeople}
          setSelected={(value) => {
            setSelectedOption({ group: defaultEntity, person: defaultEntity, thing: defaultEntity });
            handleSelectedRadio('thingsOrPeople', value);
          }}
        />
      </Stack>
      {selectedRadio.thingsOrPeople !== RadioButtons.ALL_THING_PEOPLE
        && <>
          <Stack>
            <Typography variant="subtitle2">{t('admin:page.subscriptions-list.individual-group.label')}</Typography>
            <RadioButtonSet
              data-testid="individual-or-group"
              options={individualOrGroupsOptions}
              selected={selectedRadio.individualOrGroup}
              setSelected={(value) => handleSelectedRadio('individualOrGroup', value)}
            />
          </Stack>
          {selectedRadio.individualOrGroup === RadioButtons.GROUP
            ? <EntityAutocomplete
              data={entityData}
              onOptionChanged={setSelectedOption}
              selectedOption={selectedOption}
              data-testid="select-group"
            />
            : <EntityLookup
              entityData={entityData}
              handleSelectedEntity={handleSelectedEntity}
              data-testid="select-entity"
            />
          }
        </>
      }

      <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', my: 1 }}>
        <Button
          data-testid="button-next"
          disabled={nextDisabled}
          onClick={onActiveNext}
          variant="contained"
        >
          {t('common:common.action.next')}
        </Button>
      </Stack>
    </Stack>
  );
};
