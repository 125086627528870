import { PropertiesDefinition } from '@eagle/common';
import { Person, PersonThingRangeResponse, PersonType, Thing } from '@eagle/core-data-types';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Chip, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { useApiErrorHandler } from '../../hooks';
import { CacheDataTypes } from '../../types';
import { ConfirmDialog } from '../confirm-dialog';
import { FetchOne, FetchOneOfAll } from '../fetch';
import { FormatTimestamp } from '../format';
import { Label } from '../label';
import { DataRow, LinkButton, PropertiesRows } from '../static-data';

interface ThingPersonDetailProps {
  canEditAssignment: boolean;
  finishPerson: () => void;
  readonly?: boolean;
  thing: Thing;
  thingPerson: PersonThingRangeResponse;
}

export const ThingPersonDetail: FC<ThingPersonDetailProps> = ({ canEditAssignment, finishPerson, readonly, thing, thingPerson }) => {
  const { t } = useTranslation(['common']);
  const { restClient } = useAuthenticated();
  const { handleUpdateError } = useApiErrorHandler();
  const { enqueueSnackbar } = useSnackbar();

  const thingPersonDetails = (person: Person, personType: PersonType, properties: PropertiesDefinition, numberOfProperties = 2): JSX.Element => (
    <Stack direction="column" spacing={2}>
      <DataRow
        action={<LinkButton link={{ display: t('common:common.action.view'), url: `/person/${person._id}` }} />}
        label={personType.display}
        value={<Typography>{person.display}</Typography>}
      />
      <PropertiesRows properties={person.properties} propertyDefinitions={properties} maxProperties={numberOfProperties} />
      {(person.tags.length > 0 && person.tags[0])
        && <DataRow
          label={t('common:terms.tag')}
          value={<>
            {person.tags.map((tag, i: number) => {
              return <Chip key={i} label={tag} size="small" sx={{ marginRight: '4px' }} />;
            })}
          </>}
        />
      }
      {Object.keys(person.labels).length > 0
        && <DataRow
          label={t('common:terms.label')}
          value={<>
            {Object.entries(person.labels).map(([key, value], i: number) => {
              return <Label key={i} label={key} value={value} size="small" />;
            })}
          </>}
        />
      }
      <Stack direction={{ xs: 'column', sm: 'row', md: 'column', lg: 'row' }}>
        {canEditAssignment
          && <ConfirmDialog
            buttonDisabled={readonly}
            sx={{ padding: 0 }}
            confirmPrompt={
              <Typography color="text.secondary" variant="body2">
                {t('common:page.thing-detail.thing-person-detail.confirm-unassign.action')}
              </Typography>
            }
            initialPrompt={t('common:page.thing-detail.person-list.unassign.action')}
            initialPromptIcon={<LinkOffIcon />}
            onConfirm={() => restClient.personThing.finish(person._id, thing._id, { role: thingPerson.role })}
            onFailure={(err) => {
              handleUpdateError(err, CacheDataTypes.THING, undefined, t('common:unassign.failure', { person: person.display, thing: thing.display }));
            }}
            onSuccess={() => { enqueueSnackbar(t('common:unassign.success', { person: person.display, thing: thing.display }), { variant: 'success' }); finishPerson(); }}
          />
        }
        {thingPerson.start
          && <Typography
            color="text.secondary"
            fontSize={12}
            fontStyle="italic"
            marginLeft="auto"
            variant="body2"
          >
            {t('common:common.labels.assigned')}&nbsp;
            <FormatTimestamp value={thingPerson.start} format="relative" />
          </Typography>}
      </Stack>
    </Stack>
  );

  return (
    <FetchOne
      id={thingPerson.personId}
      dataType={CacheDataTypes.PERSON}
      renderFactory={(person: Person) => (
        <FetchOneOfAll
          id={person.personTypeId}
          dataType={CacheDataTypes.PERSON_TYPE}
          renderFactory={(personType: PersonType) => (
            thingPersonDetails(person, personType, personType.properties)
          )}
        />
      )}
    />
  );
};
