import { Account, ThingLifeCycleStateResponse } from '@eagle/core-data-types';
import { LockSharp } from '@mui/icons-material';
import { InputAdornment, Stack, Tooltip } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { LOOKUP_DEBOUNCE_TIME, SERVICE_CENTER_ROLE } from '../../constants';
import { usePromise } from '../../hooks';
import { testid } from '../../util';
import { Lookup } from '../lookup';
import { SelectionCheckBox } from '../selection-check-box';

interface ServiceCenterSelectProps {
  isNewService?: boolean;
  sharedThingId: string;
  servicedBy: string | null;
  setServicedBy: Dispatch<SetStateAction<string | null>>;
  lifeCycleState: ThingLifeCycleStateResponse;
}

export const ServiceCenterSelect: FC<ServiceCenterSelectProps> = ({ lifeCycleState, sharedThingId, servicedBy, setServicedBy, isNewService = true }) => {
  const { t } = useTranslation(['admin', 'common']);
  const { restClient } = useAuthenticated();
  const disabled = !isNewService;

  const handleFindAccounts = useMemo<(_: string) => Promise<(Account)[]>>(() => {
    return async (text: string) => restClient.sharedThing.getStakeholderAccounts(sharedThingId, SERVICE_CENTER_ROLE, { search: text },
    ).then((accounts) => {
      return accounts || [];
    }).catch((err) => {
      console.error(err);
      enqueueSnackbar(t('common:component.search.hint.unexpected-error-message'), { variant: 'error' });
      return [];
    });
  }, [restClient, sharedThingId, t]);

  const [stakeholderAccount] = usePromise(async () => {
    if (!servicedBy) return undefined;
    try {
      const account = await restClient.sharedThing.getStakeholderAccount(sharedThingId, SERVICE_CENTER_ROLE, servicedBy);
      return account;
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('common:component.search.hint.unexpected-error-message'), { variant: 'error' });
      return undefined;
    }
  }, [restClient, servicedBy, sharedThingId]);

  useEffect(() => {
    if (!isNewService) return;
    const preselectedServiceCenterAccountId = lifeCycleState.stakeholders?.[SERVICE_CENTER_ROLE]?._id || null;
    setServicedBy(preselectedServiceCenterAccountId);
  }, []);

  return (
    <Stack spacing={1} sx={{ pb: 1 }}>
      <Lookup<Account>
        data-testid={testid`${SERVICE_CENTER_ROLE}-account-lookup`}
        debounceTime={LOOKUP_DEBOUNCE_TIME}
        disabled={disabled}
        loadOnRender
        label={t('common:page.thing-detail.service-history-dialog.serviced-by.labels')}
        getOptionTestId={(option) => {
          return testid`${SERVICE_CENTER_ROLE}-account-item-${option._id}`;
        }}
        handleCompareItems={(option, value) => option._id === value._id}
        handleFindItems={handleFindAccounts}
        handleFormatListItem={(item) => <span>{item.display}</span>}
        handleFormatSelectedItem={(item) => item.display}
        isSearch
        onItemSelected={(account) => {
          setServicedBy(account?._id ?? null);
        }}
        placeholder={t('common:component.lookup.hint.placeholder')}
        selectedItem={stakeholderAccount ?? null}
        size="small"
        inputAdornment={disabled ? <InputAdornment position="start"><Tooltip title={t('common:page.thing-detail.service-history-dialog.unable-to-update-serviced-by.labels')}><LockSharp color='disabled' /></Tooltip></InputAdornment> : undefined}
        textFieldStyles={disabled ? { '.MuiOutlinedInput-root.MuiInputBase-adornedEnd': { pr: 1 } } : {}}
      />
      <SelectionCheckBox
        checked={servicedBy === null}
        disabled={disabled}
        data-testid={testid`${SERVICE_CENTER_ROLE}-serviced-by-third-party-checkbox`}
        handleClick={() => {
          setServicedBy(null);
        }}
        label={t('common:page.thing-detail.service-history-dialog.serviced-by-third-party.labels')}
        sx={{ pl: 0 }}
      />
    </Stack>
  );
};
