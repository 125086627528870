/* eslint-disable react-hooks/exhaustive-deps */
import { RoleFunction } from '@eagle/common';
import { Recipient, RecipientType, Subscription, SubscriptionUpdatableProps } from '@eagle/core-data-types';
import SaveIcon from '@mui/icons-material/Save';
import { Link, Stack, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { FC, Suspense, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref, useParams } from 'react-router-dom';
import { useAuthenticated } from '../../auth';
import { Alert, Breadcrumbs, DeleteIcon, MiddleSpinner, PortalFeatureIcons, useBoolFlag } from '../../components';
import { T_MANY, T_ONE } from '../../constants';
import { useApiErrorHandler, useCustomRoutes, useDynamicModule, useFetchOneCache, useMaxScreen } from '../../hooks';
import { CacheDataTypes, FeatureIcons, PageAction } from '../../types';
import { DetailPage } from '../detail';
import { DeleteSubscriptionDialog } from './delete-subscription-dialog';
import { FeatureAlertSettingsCard } from './feature-alert-settings-card';
import { FeatureAlertSettingsCardV2 } from './feature-alert-settings-card-v2';
import { NotificationRecipientCard } from './notification-recipient-card';
import { EntityState, Props, Settings } from './subscription-detail.types';
import { isIndividualUserRecipient } from './subscription-detail.utils';
import { ThingPeopleFiltersCard } from './thing-people-filters-card';

export const SubscriptionDetail: FC<Props> = ({ isProfilePage = false, subscription = undefined }) => {
  const { restClient, userInfo } = useAuthenticated();
  const { handleUpdateError } = useApiErrorHandler();
  const { subscription: subscriptionRoute, subscriptions: subscriptionsRoute } = useCustomRoutes();
  const subscriptionCache = useFetchOneCache(isProfilePage ? CacheDataTypes.MY_SUBSCRIPTION : CacheDataTypes.SUBSCRIPTION);
  const href = useHref(isProfilePage ? `/profile/${subscriptionRoute}` : `/${subscriptionsRoute}`);
  const mediumScreen = useMaxScreen('lg');
  const { subscriptionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { t } = useTranslation(['admin', 'common']);
  const hasDeletePermissions = isProfilePage ? true : userInfo.hasRoleFunction(RoleFunction.ADMINISTRATOR);
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);
  const isSubscriptionsV2Enabled = useBoolFlag('admin-subscriptions-v2-feature-temporary');

  const [saveDisable, setSaveDisable] = useState(true);
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [thingPeopleState, setThingPeopleState] = useState<EntityState>();
  const [featureAlertState, setFeatureAlertState] = useState<Settings>();
  const [recipientState, setRecipientState] = useState<Recipient>();
  const [defaultSubscription, setDefaultSubscription] = useState<SubscriptionUpdatableProps>();
  const [thingPeopleIsValid, setThingPeopleIsValid] = useState(false);
  const [recipientIsValid, setRecipientIsValid] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const isReadOnly = (data: Subscription): boolean => {
    return (isProfilePage && data.recipient?.type !== RecipientType.INTERNAL_INDIVIDUAL_USER) || Boolean(data.finish);
  };

  useEffect(() => {
    if (!defaultSubscription) return;

    const updatedSubscription = {
      recipient: { ...recipientState },
      alertFilters: {
        groupId: thingPeopleState?.group._id ?? null,
        personId: thingPeopleState?.person._id ?? null,
        thingId: thingPeopleState?.thing._id ?? null,
        ...featureAlertState,
      },
    };
    const noChange = isEqual(defaultSubscription, updatedSubscription);
    const isValid = thingPeopleIsValid && recipientIsValid;

    setSaveDisable(!isValid || noChange);
  }, [defaultSubscription, thingPeopleState, featureAlertState, recipientState, thingPeopleIsValid, recipientIsValid]);

  const renderPageContent = (data: Subscription): JSX.Element => {
    if (!defaultSubscription) setDefaultSubscription({ alertFilters: data.alertFilters, recipient: data.recipient });

    const readOnly = isReadOnly(data);

    return <Suspense fallback={<MiddleSpinner />}>
      <Stack
        direction="column"
        flex={[2, 2]}
        spacing={2}
        sx={{ minWidth: 0 }}
      >
        {(readOnly && isProfilePage) && <Alert severity='warning'>{t('common:page.profile.contact-administrator.hint')}</Alert>}
        <ThingPeopleFiltersCard
          alertFilters={data.alertFilters}
          fieldDisabled={fieldDisabled || readOnly}
          getThingsPeopleState={setThingPeopleState}
          setThingPeopleIsValid={setThingPeopleIsValid}
        />
        {isSubscriptionsV2Enabled ? (
          <>
            {thingPeopleState && defaultSubscription && (
              <FeatureAlertSettingsCardV2
                alertFilters={data.alertFilters}
                defaultSubscription={defaultSubscription}
                fieldDisabled={fieldDisabled || readOnly}
                setFeatureAlertState={setFeatureAlertState}
                thingPeopleState={thingPeopleState}
              />
            )}
          </>
        )
          : <FeatureAlertSettingsCard
            alertFilters={data.alertFilters}
            fieldDisabled={fieldDisabled || readOnly}
            getSettingsState={setFeatureAlertState}
          />
        }

        <Suspense fallback={<MiddleSpinner />}>
          <NotificationRecipientCard
            isProfilePage={isProfilePage}
            fieldDisabled={fieldDisabled || readOnly}
            getRecipientState={setRecipientState}
            recipient={data.recipient}
            setRecipientIsValid={setRecipientIsValid}
          />
        </Suspense>
      </Stack>
      <Stack direction="column" flex={mediumScreen ? [2, 2] : [3, 3]} spacing={2} />
    </Suspense>;
  };

  const updateSubscription = async (): Promise<void> => {
    const update = (): Promise<Subscription> => {
      if (!subscriptionId || !thingPeopleState || !featureAlertState || !recipientState) {
        throw new Error('Invalid state.');
      }

      if (isProfilePage) {
        if (!isIndividualUserRecipient(recipientState)) {
          throw new Error('Invalid state.');
        }
        return restClient.my.subscription.partialUpdate(subscriptionId, {
          recipient: {
            channel: recipientState.channel,
            localization: recipientState.localization,
          },
          alertFilters: {
            groupId: thingPeopleState.group._id,
            personId: thingPeopleState.person._id,
            thingId: thingPeopleState.thing._id,
            ...featureAlertState,
          },
        });
      }
      return restClient.subscription.partialUpdate(subscriptionId, {
        recipient: recipientState,
        alertFilters: {
          groupId: thingPeopleState.group._id,
          personId: thingPeopleState.person._id,
          thingId: thingPeopleState.thing._id,
          ...featureAlertState,
        },
      });
    };

    setFieldDisabled(true);
    setSaveDisable(true);

    try {
      setSaveDisable(true);
      const updatedSubscription = await update();
      await subscriptionCache.invalidate(subscriptionId);
      enqueueSnackbar(t('common:common.hint.update-success', { entity: t('common:terms.subscription', { count: T_ONE }) }), { variant: 'success' });
      setDefaultSubscription(updatedSubscription);
    } catch (err) {
      setSaveDisable(false);
      handleUpdateError(err, CacheDataTypes.SUBSCRIPTION, t('common:terms.subscription', { count: T_ONE }));
    }

    setFieldDisabled(false);
  };

  const breadcrumbs = (
    <Breadcrumbs>
      <Link
        color="inherit"
        href={href}
        underline="hover"
      >
        {t('common:terms.subscription', { count: T_MANY })}
      </Link>
      <Typography color="text.primary" data-testid="subscription-detail-subtitle-breadcrumb">{t('admin:page.subscription-detail.title')}</Typography>
    </Breadcrumbs>
  );

  const loadData = useCallback(
    () => {
      if (!subscription) return subscriptionCache.one<Subscription>(subscriptionId);
      return Promise.resolve(subscription);
    },
    [subscriptionId, userInfo.accountId, defaultSubscription],
  );

  const actions = (data: Subscription): PageAction[] => {
    const readOnly = isReadOnly(data);

    const baseActions = [
      {
        disabled: saveDisable || readOnly,
        icon: <SaveIcon />,
        label: t('common:common.action.save-changes'),
        onPromiseClick: updateSubscription,
      },
    ];

    const pageActions = hasDeletePermissions
      ? [{
        disabled: readOnly,
        icon: <DeleteIcon />,
        label: t('common:common.action.delete-entity', { entity: t('common:terms.subscription', { count: T_ONE }) }),
        onClick: () => setOpenDialog(true),
        sx: { color: theme.palette.error.main },
      },
      ...baseActions,
      ]
      : baseActions;

    return pageActions;
  };

  if (!moduleLoaded) return <MiddleSpinner />;

  return (
    <Box sx={{
      display: 'flex',
      flex: [1, 1],
      flexDirection: 'column',
      minWidth: 0,
    }}>
      <DetailPage<Subscription>
        data-testid='subscription-detail'
        key={subscriptionId}
        actions={actions}
        breadcrumbs={breadcrumbs}
        loadData={loadData}
        pageIcon={module?.AlertIcon && <module.AlertIcon />}
        renderDisplay={({ _id }) => _id}
        renderPageContent={renderPageContent}
        renderPageTitle={({ _id }) => _id}
      />
      {subscriptionId
        && <DeleteSubscriptionDialog
          handleClose={() => setOpenDialog(false)}
          isProfilePage={isProfilePage}
          open={openDialog}
          subscriptionId={subscriptionId}
        />
      }
    </Box>
  );
};

export default SubscriptionDetail;
