/* eslint-disable react-hooks/exhaustive-deps */
import { RoleFunction } from '@eagle/common';
import { AlertResponse, getThingFeatureCombinations, LastThingEvent, Thing, ThingType } from '@eagle/core-data-types';
import { FeatureTypes } from '@eagle/data-function-types';
import {
  AlertsTable,
  ALERTS_DAYS_LIMIT,
  Breadcrumbs,
  CacheDataTypes,
  DetailPage,
  DynamicIcon,
  evaluate,
  FeatureIcons,
  FeatureIds,
  FetchOneOfAll,
  FindItemsDeferredPaginatedResponse,
  FlexBox,
  FormatTimestamp,
  getLastThingLocation,
  getListResultDescription,
  getThingEventServerTime,
  LastContact,
  LastThingEvents,
  LastThingStateProvider,
  MediaCard,
  MediaListPageType,
  MiddleSpinner,
  Pagination,
  PortalFeatureIcons,
  Portals,
  Query,
  RECENT_EVENT_DATA_MAX_DAYS_FLAG,
  ServiceHistoryCard,
  ThingInlayMap,
  ThingPersonCard,
  TRACK_THING_DETAIL_DEFAULT_TEMPLATE,
  T_MANY,
  T_ONE,
  Undefinable,
  useAuthenticated,
  useBoolFlag,
  useCustomRoutes,
  useDynamicModule,
  useFetchOneCache,
  useHasAuthorization,
  useLastThingState,
  useNumberFlag,
  usePromise,
  useUiTemplate
} from '@eagle/react-common';
import { Box, Card, CardContent, Link, Typography } from '@mui/material';
import Axios from 'axios';
import { DateTime } from 'luxon';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref, useNavigate, useParams } from 'react-router-dom';
import { DataDrivenThingEventsV2 } from './cards/data-driven-thing-event-v2';
import { DataDrivenThingEvents } from './cards/data-driven-thing-events';
import { ThingEvents } from './cards/thing-event';
import { ThingDetailProps, ThingMediaProps } from './thing-detail.types';

const MEDIA_DAYS_LIMIT = 30;
const MEDIA_LIMIT = 6;
const DATA_VIEWER_ROLES = [RoleFunction.DATA_VIEWER] as const;
const CAMERA_ROLES = [RoleFunction.MEDIA_VIEWER] as const;
const ALERT_ROLES = [RoleFunction.ALERT_VIEWER] as const;
const SERVICE_ROLES = [RoleFunction.SERVICE_VIEWER, RoleFunction.SERVICE_ADMINISTRATOR] as const;
export const EVENT_DATA_MAX_DAYS_FLAG = 'track-thing-detail-events-card-days-back-feature';

export const thingDetailItemRenderer = (entity: Thing, thingType: ThingType, portal: Portals, groupIds: string[], noGroupsText: string, isClickable: boolean, personThingCard: JSX.Element, thingInlayMap: JSX.Element, thingMedia: JSX.Element, thingAlerts: JSX.Element, thingEvents: JSX.Element, thingServiceHistory: JSX.Element, template: unknown, lastThingEvents: Undefinable<LastThingEvents>): JSX.Element => {
  return <>{evaluate(template, { entity, thingType, portal, groupIds, noGroupsText, isClickable, personThingCard, thingInlayMap, thingMedia, thingAlerts, thingEvents, thingServiceHistory, lastThingEvents })}</>;
};

const ThingAlerts: FC<{ thing: Thing }> = ({ thing }): JSX.Element => {
  const { restClient } = useAuthenticated();
  const { t } = useTranslation(['common', 'terms', 'track']);
  const [isRecentAlertsView, setIsRecentAlertsView] = useState(true);
  const maxDays = useNumberFlag(RECENT_EVENT_DATA_MAX_DAYS_FLAG) || ALERTS_DAYS_LIMIT;

  const handleQueryChanged = useCallback((_: Query, pagination: Pagination, isDaysLimit: boolean): FindItemsDeferredPaginatedResponse<AlertResponse> => {
    const cancelToken = Axios.CancelToken.source();
    // TODO TP-5859 update axios, use AbortController instead of deprecated CancelToken
    const startDate = DateTime.now().minus(isDaysLimit ? { days: maxDays } : { years: 3 });
    return {
      cancel: () => cancelToken.cancel(),
      promise: restClient.alert.getAlertsByThingId(thing._id, {
        dateRangeStart: startDate.toUTC().toJSDate(),
        limit: pagination.limit,
        skip: pagination.skip,
        sort: JSON.stringify({ occurred: -1 }),
      }, { cancelToken: cancelToken.token }),
    };
  }, [restClient]);

  return (
    <Card sx={{ '& .MuiPaper-root': { boxShadow: 'none' } }}>
      <AlertsTable
        onQueryChanged={handleQueryChanged}
        renderTitle={(alerts, matchCount) => (
          <CardContent>
            {
              isRecentAlertsView ?
                <Typography variant="h5" color="text.primary" sx={{ mb: 2 }}>{t('common:component.alert-table.labels.recent-alert')}
                  <Typography component="span" variant="body2" color="text.secondary" fontStyle="italic" sx={{ display: 'block' }}>
                    {!alerts.length ?
                      t('common:component.media-data.hint.displaying-last', { count: maxDays }) :
                      t('common:component.alert-table.hint.results', { count: matchCount, dayCount: maxDays, entity: t('common:terms.alert', { count: matchCount }) })}
                  </Typography>
                </Typography>
                :
                <Typography variant="h5" color="text.primary" sx={{ mb: 2 }}> {t('common:terms.alert', { count: T_MANY })}
                  <Typography component="span" variant="body2" color="text.secondary" fontStyle="italic" sx={{ display: 'block' }}>
                    {getListResultDescription({ count: matchCount, entityKey: 'common:terms.alert', t })}
                  </Typography>
                </Typography>
            }
          </CardContent>
        )}
        showThing={false}
        smallTable={true}
        showGroups={false}
        showFeature={false}
        updateIsRecentAlertsView={setIsRecentAlertsView}
        isRecentAlertsView={isRecentAlertsView}
      />
    </Card>
  );
};

export const ThingMedia: FC<ThingMediaProps> = ({ cameraFeatures, thing, thingType }) => (
  <MediaCard
    displayOnCard
    entity={thing}
    entityTypeFeatures={thingType.features}
    features={cameraFeatures}
    filterMediaFeature
    filters={{
      thingId: thing._id,
      featureTypeId: FeatureTypes.CAMERA_V0,
    }}
    pageType={MediaListPageType.THINGS}
    sort="-start"
    url="/api/v2/media-data"
    mediaDaysLimit={MEDIA_DAYS_LIMIT}
    limit={MEDIA_LIMIT}
  />
);

export const ThingDetail: FC<ThingDetailProps> = (props) => {
  return (
    <LastThingStateProvider>
      <ThingDetailInner {...props} />
    </LastThingStateProvider>
  );
};

const ThingDetailInner: FC<ThingDetailProps> = ({ thing = undefined, useDataDrivenEventList = false }) => {
  const { things: customRoutesThings, history: customRoutesHistory } = useCustomRoutes();
  const { restClient, userInfo } = useAuthenticated();
  const { t } = useTranslation(['common', 'terms', 'track', 'manage']);
  const params = useParams();
  const thingId = thing?._id || params.thingId;
  const navigate = useNavigate();
  const flagThingHistory = useBoolFlag('track-thing-history-feature');
  const flagEventCard = useBoolFlag('track-thing-detail-events-card-feature');
  const flagServicingModule = useBoolFlag('portals-servicing-module-feature');
  const flagDataDrivenThingEventsV2 = useBoolFlag('track-event-card-v2-data-driven-feature-temporary');
  const { hasAuthorization } = useHasAuthorization();
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);
  const [fetchState] = useLastThingState();
  const { template } = useUiTemplate('track-thing-detail', TRACK_THING_DETAIL_DEFAULT_TEMPLATE);
  const alertPermissions = hasAuthorization(ALERT_ROLES);
  const cameraPermission = hasAuthorization(CAMERA_ROLES);
  const mapPermission = hasAuthorization(DATA_VIEWER_ROLES);
  const eventsPermission = hasAuthorization(DATA_VIEWER_ROLES) && flagEventCard;
  const servicePermissions = hasAuthorization(SERVICE_ROLES);

  const href = useHref(`/${customRoutesThings}`);
  const things = useFetchOneCache(CacheDataTypes.THING);
  const isServiceHistoryVisible = flagServicingModule && servicePermissions && thingId;

  const loadData = useCallback(
    () => {
      if (!thing) return things.one<Thing>(thingId);
      return Promise.resolve(thing);
    },
    [thingId, userInfo.accountId]
  );

  const [lastThingLocation] = usePromise<Undefinable<LastThingEvent>>(
    async () => {
      if (!thingId) throw Error(t('common:common.hint.last-contact-no-id', { entity: t('terms:thing', { count: T_ONE }) }));
      return getLastThingLocation(restClient, thingId);
    },
    [restClient]
  );

  const [lastThingEvents, , lastThingEventsStatus] = usePromise(() => {
    if (!thingId) throw Error(t('common:common.hint.last-contact-no-id', { entity: t('terms:thing', { count: T_ONE }) }));
    return fetchState(thingId);
  }, [fetchState, thingId]);

  const ThingDetailInlayMap: FC<{ data: Thing; thingType: ThingType }> = ({ data, thingType }): JSX.Element => (
    <FlexBox sx={{ flexGrow: 0, height: 400 }}>{mapPermission && thingId && thingType.features.some(({ featureId }) => featureId === FeatureIds.TRACKING) && <ThingInlayMap thing={data} />}</FlexBox>
  );

  const ThingDetailMedia: FC<{ data: Thing; thingType: ThingType; cameraFeatures: string[] }> = ({ data, thingType, cameraFeatures }): JSX.Element => (
    <>{cameraPermission && <ThingMedia cameraFeatures={cameraFeatures} thing={data} thingType={thingType} />}</>
  );

  const ThingDetailAlerts: FC<{ data: Thing }> = ({ data }): JSX.Element => (
    <>{alertPermissions && <ThingAlerts thing={data} />}</>
  );

  const ThingDetailEvents: FC<{ data: Thing }> = ({ data }) => {
    if (!eventsPermission) {
      return null;
    }
    if (flagDataDrivenThingEventsV2) {
      return <DataDrivenThingEventsV2 thing={data} />;
    }
    return useDataDrivenEventList ? <DataDrivenThingEvents thing={data} /> : <ThingEvents thing={data} />;
  };

  const ThingDetailServiceHistory: FC<{ data: Thing }> = ({ data }): JSX.Element => (
    <>{isServiceHistoryVisible && data.sharedThingId && <ServiceHistoryCard thingId={thingId} thing={data} />}</>
  );

  const renderPageContent = (data: Thing): JSX.Element => {
    return (
      <FetchOneOfAll
        id={data.thingTypeId}
        dataType={CacheDataTypes.THING_TYPE}
        renderFactory={(thingType: ThingType) => {
          const cameraFeature = thingType.features.find((feature) => feature.featureId === 'camera');
          const cameraFeatures = cameraFeature ? getThingFeatureCombinations(cameraFeature) : [];

          const personThingCard = <ThingPersonCard thing={data} portal={Portals.TRACK} />;
          const thingInlayMap = <ThingDetailInlayMap data={data} thingType={thingType} />;
          const thingMedia = <ThingDetailMedia data={data} thingType={thingType} cameraFeatures={cameraFeatures} />;
          const thingAlerts = <ThingDetailAlerts data={data} />;
          const thingEvents = <ThingDetailEvents data={data} />;
          const thingServiceHistory = <ThingDetailServiceHistory data={data} />;

          return thingDetailItemRenderer(
            data,
            thingType,
            Portals.TRACK,
            data.groupIds,
            t('track:page.thing-detail.no-thing-group.hint'),
            false,
            personThingCard,
            thingInlayMap,
            thingMedia,
            thingAlerts,
            thingEvents,
            thingServiceHistory,
            template,
            lastThingEvents,
          );
        }}
      />
    );
  };

  const viewHistory = (): void => {
    const date = lastThingLocation?.occurred ? DateTime.fromJSDate(lastThingLocation.occurred) : DateTime.now();

    navigate(`/${customRoutesHistory}`, {
      state: {
        dateEnd: date.plus({ days: 1 }).startOf('day').toJSDate(),
        dateStart: date.startOf('day').toJSDate(),
        entityId: thingId,
        entityItemFields: {
          entity: CacheDataTypes.THING,
          entityType: CacheDataTypes.THING_TYPE,
          entityTypeKey: 'thingTypeId',
        },
        filters: [],
        focused: true,
      },
    });
  };

  const actions = flagThingHistory
    ? [
      {
        label: t('track:common.action.view-history'),
        onClick: viewHistory,
        icon: <DynamicIcon icon={module?.HistoryIcon ? <module.HistoryIcon /> : null} />,
      },
    ]
    : [];

  const breadcrumbs = (
    <Breadcrumbs>
      <Link
        color="inherit"
        href={href}
        underline="hover"
      >
        {t('terms:thing', { count: T_MANY })}
      </Link>
      <Typography color="text.primary" data-testid="thing-detail-subtitle-breadcrumb">{t('common:page.thing-detail.title')}</Typography>
    </Breadcrumbs>
  );

  if (!moduleLoaded) return <MiddleSpinner />;

  return (
    <Box sx={{
      display: 'flex',
      flex: [1, 1],
      flexDirection: 'column',
      minWidth: 0,
    }}>
      <DetailPage<Thing>
        data-testid='thing-detail'
        actions={actions}
        breadcrumbs={breadcrumbs}
        loadData={loadData}
        pageIcon={module?.ThingIcon && <module.ThingIcon />}
        renderDisplay={({ display }) => display}
        renderPageContent={renderPageContent}
        renderPageTitle={(data: Thing) => {
          if (lastThingEventsStatus === 'pending') return t('common:common.labels.loading');
          return <>
            {data.display}
            <Box sx={{ alignItems: 'center', display: 'flex', position: 'relative' }}>
              <LastContact
                lastThingEvent={lastThingEvents?.latest}
                sx={{ mr: 1 }}
              />
              {lastThingEvents?.latest &&
                <Typography data-ignore-title component='div' variant="caption">
                  {t('common:component.last-contact.labels.contact-time')} <FormatTimestamp format='relative' value={getThingEventServerTime(lastThingEvents.latest)} />
                </Typography>
              }
            </Box>
          </>;
        }}
      />
    </Box>
  );
};

export default ThingDetail;
