import { AlertFilters, Channel, ChannelType, DefaultableChannel, ExternalRecipient, IndividualRecipient, Recipient, RecipientType, User, UserGroupRecipient } from '@eagle/core-data-types';
import { Typography } from '@mui/material';
import { capitalize, upperCase } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchAllCache, useFetchOneCache, usePromise } from '../../hooks';
import { CacheDataTypes, Maybe, Nullable } from '../../types';
import { FetchOne, FetchOneOfAll } from '../fetch';
import { EntityData, InternalRecipientDisplayProps, InternalRecipientType, SourceEntityType, SubscriptionUserRecipientDataType } from './subscriptions.types';

const NUMBER_OF_COLUMNS = 4;
export const COLUMN_WIDTH = `${100 / NUMBER_OF_COLUMNS}%`;

export const isUserGroupRecipient = (recipient: Recipient): recipient is UserGroupRecipient => {
  return recipient.type === RecipientType.INTERNAL_GROUP || recipient.type === RecipientType.INTERNAL_USER_GROUP;
};

export const isIndividualRecipient = (recipient: Recipient): recipient is IndividualRecipient => {
  return recipient.type === RecipientType.INTERNAL_INDIVIDUAL_USER || recipient.type === RecipientType.INTERNAL_INDIVIDUAL_PERSON;
};

const displayStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const ExternalRecipientDisplay: FC<{ recipient: ExternalRecipient }> = ({ recipient }): JSX.Element => {
  const getData = (channel: Channel): string => {
    switch (channel.type) {
      case ChannelType.EMAIL:
        return channel.address;
      case ChannelType.SMS:
        return channel.number;
      case ChannelType.API:
        return channel.type;
    }
  };

  return <Typography sx={displayStyle} data-testid="recipient-display">{getData(recipient.channel)}</Typography>;
};

export const InternalRecipientDisplayOneCache: FC<InternalRecipientDisplayProps> = ({ dataType, displaySubText, recipient }): JSX.Element => {
  const cache = useFetchOneCache(dataType);
  const [data, error] = usePromise(
    () => cache.one<InternalRecipientType>(recipient.referenceId),
    [cache, recipient],
  );

  if (!data || error) return <></>;
  return <InternalRecipientDisplayImpl data={data} dataType={dataType} displaySubText={displaySubText} recipient={recipient} />;
};

export const InternalRecipientDisplayAllCache: FC<InternalRecipientDisplayProps> = ({ dataType, displaySubText, recipient }): JSX.Element => {
  const cache = useFetchAllCache(dataType);
  const [data, error] = usePromise(
    () => cache.one<InternalRecipientType>(recipient.referenceId),
    [cache, recipient],
  );

  if (!data || error) return <></>;
  return <InternalRecipientDisplayImpl data={data} dataType={dataType} displaySubText={displaySubText} recipient={recipient} />;
};

export const InternalRecipientDisplayImpl: FC<InternalRecipientDisplayProps & { data: InternalRecipientType }> = ({ data, dataType, displaySubText, recipient }): JSX.Element => {
  const getIndividualData = (channel: DefaultableChannel): Maybe<string> => {
    switch (channel.type) {
      case ChannelType.EMAIL:
        if (channel.address) return channel.address;
        if (dataType === CacheDataTypes.ADMIN_USER) return (data as User).notificationChannel?.email ?? (data as User).email;
        return null;
      case ChannelType.SMS:
        if (channel.number) return channel.number;
        if (dataType === CacheDataTypes.ADMIN_USER) return (data as User).notificationChannel?.sms;
        return null;
      case ChannelType.API:
        return channel.type;
    }
  };

  if (isUserGroupRecipient(recipient)) {
    return <>
      <Typography data-testid="recipient-display" sx={displayStyle}>{data.display}</Typography>
      {displaySubText
        && <Typography
          data-testid="recipient-channel"
          sx={displayStyle}
          variant="caption"
        >
          {recipient.channelType === ChannelType.EMAIL ? capitalize(recipient.channelType) : upperCase(recipient.channelType)}
        </Typography>
      }
    </>;
  }

  return <>
    <Typography sx={displayStyle} data-testid="recipient-display">{data.display}</Typography>
    {displaySubText && <Typography sx={displayStyle} variant="caption" data-testid="recipient-channel">{getIndividualData(recipient.channel)}</Typography>}
  </>;
};

export const RecipientDisplay: FC<{ recipient: Recipient; displaySubText?: boolean; userDataType: SubscriptionUserRecipientDataType }> = ({ recipient, displaySubText = true, userDataType }): JSX.Element => {
  switch (recipient.type) {
    case RecipientType.EXTERNAL:
      return <ExternalRecipientDisplay recipient={recipient} />;
    case RecipientType.INTERNAL_GROUP:
      return <InternalRecipientDisplayAllCache recipient={recipient} dataType={CacheDataTypes.GROUP} displaySubText={displaySubText} />;
    case RecipientType.INTERNAL_USER_GROUP:
      return <InternalRecipientDisplayAllCache recipient={recipient} dataType={CacheDataTypes.USER_GROUP} displaySubText={displaySubText} />;
    case RecipientType.INTERNAL_INDIVIDUAL_PERSON:
      return <InternalRecipientDisplayOneCache recipient={recipient} dataType={CacheDataTypes.PERSON} displaySubText={displaySubText} />;
    case RecipientType.INTERNAL_INDIVIDUAL_USER:
      return <InternalRecipientDisplayOneCache recipient={recipient} dataType={userDataType} displaySubText={displaySubText} />;
  }
};

export const getEntityData = (alertFilters: AlertFilters): Nullable<EntityData> => {
  const { groupId, personId, thingId } = alertFilters;
  if (groupId) return { id: groupId, dataType: CacheDataTypes.GROUP };
  if (personId) return { id: personId, dataType: CacheDataTypes.PERSON };
  if (thingId) return { id: thingId, dataType: CacheDataTypes.THING };
  return null;
};

export const SourceDisplay: FC<{ entityData: Nullable<EntityData> }> = ({ entityData }) => {
  const { t } = useTranslation(['admin']);

  if (!entityData) return <Typography>{t('admin:page.subscriptions-list.all-things-people.label')}</Typography>;

  const { id, dataType } = entityData;

  if (dataType === CacheDataTypes.GROUP) {
    return (
      <FetchOneOfAll
        id={id}
        dataType={dataType}
        renderFactory={(entity: SourceEntityType) => <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{entity.display}</Typography>}
      />
    );
  }

  return (
    <FetchOne
      id={id}
      dataType={dataType}
      renderFactory={(entity: SourceEntityType) => <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{entity.display}</Typography>}
    />
  );
};
