import L from 'leaflet';

export const ALERT_LIST_RANGE_KEY = 'alert-list-range';
export const ALERTS_DAYS_LIMIT = 30;
export const API_CALL_SEARCH_TEXT_LENGTH = 3;
export const APP_BANNER_HEIGHT = 36.5;
export const APP_BAR_HEIGHT = 64;
export const BOUNDS_OF_EARTH = L.latLngBounds(L.latLng(-85, -Infinity), L.latLng(85, Infinity));
export const CAMERA_CONTENT_DATE_RANGE = 10;
export const CLUSTER_BOUNDS_PADDING = 0.1;
export const COLLAPSE_DEFAULT_TIME = 300;
export const DEFAULT_TIME_ZONE = 'utc';
export const DRAWER_FULL_WIDTH = 240; // 'var(--side-menu-max-width)'
export const DRAWER_MINI_WIDTH = 60; // 'var(--side-menu-min-width)'
export const EVENT_DATA_LEFT_RIGHT_SPLIT = 3;
export const EVENT_DATA_MAX_DAYS = 30;
export const FILTER_LOOKUP_LIMIT = 25;
export const HEIGHT_TO_CLOSE_DRAWER = 600;
export const HERE_MAP_API_KEY = 'map-api-key';
export const HERE_MAP_APP_CODE = 'map-code';
export const HERE_MAP_APP_ID = 'map-id';
export const HISTORY_MAP_CENTER = L.latLng(-37.8136, 144.9631);
export const INLAY_MAP_DEFAULT_ZOOM = 14.5;
export const LOOKUP_DEBOUNCE_TIME = 300;
export const MAP_DEBOUNCE_TIME = 300;
export const MAP_FLY_TO_DURATION = 2;
export const MAP_MAX_ZOOM = 18;
export const MAP_MIN_ZOOM = 2.5;
export const MAP_RELOAD_TIME_HOURS = 12;
export const PHONE_MIN_LENGTH = 3;
export const ROUTING_GET_REQUEST_LIMIT = 495;
export const ROUTING_POST_REQUEST_LIMIT = 50000;
export const SEARCH_DEBOUNCE = 350;
export const SEARCH_RESULT_THING_LIMIT = 4;
export const SERVICE_CENTER_ROLE = 'service-centre';
export const SIDEBAR_FULL_WIDTH = 300;
export const SIDEBAR_WIDTH = 72;
export const SPIRAL_CLUSTER_THRESHOLD = 7;
export const T_MANY = 999;
export const T_ONE = 1;
export const THING_EVENT_API_LIMIT = 500;
export const TIMEZONE_FORMAT_LONG_WITH_UTC = "ZZZZZ '(UTC 'Z')'";
export const WIDTH_TO_CLOSE_DRAWER = 1000;
export const LAST_CONTACT_EXCLUDED_EVENTS = ['device-installed', 'device-uninstalled', 'suppression-start', 'suppression-finish', 'person-changed'];
export let API_CALL_TEXT_LENGTH = API_CALL_SEARCH_TEXT_LENGTH;

export const setApiCallTextLength = (length: number): void => {
  API_CALL_TEXT_LENGTH = length;
};
